/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
// import * as microsoftTeams from '@microsoft/teams-js';
import { RequestPendingError, Session, SessionInviteOptions } from 'sip.js';
import {
  SessionDescriptionHandler,
  SessionDescriptionHandlerOptions,
} from 'sip.js/lib/platform/web';

/** Helper function to enable/disable media tracks. */
export function enableReceiverTracks(
  enable: boolean,
  session: Session,
  isVideo?: Boolean,
): void {
  if (!session) {
    throw new Error('Session does not exist.');
  }

  const sessionDescriptionHandler =
    session.sessionDescriptionHandler as SessionDescriptionHandler;
  if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
    throw new Error(
      "Session's session description handler not instance of SessionDescriptionHandler.",
    );
  }

  const { peerConnection } = sessionDescriptionHandler;
  if (!peerConnection) {
    throw new Error('Peer connection closed.');
  }

  peerConnection.getReceivers().forEach(receiver => {
    if (receiver.track) {
      if (receiver.track.kind === (isVideo ? 'video' : 'audio'))
        receiver.track.enabled = enable;
    }
  });
}

/** Helper function to enable/disable media tracks. */
export function enableSenderTracks(
  enable: boolean,
  session: Session,
  isVideo?: Boolean,
): void {
  if (!session) {
    throw new Error('Session does not exist.');
  }

  const { sessionDescriptionHandler } = session;
  if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
    throw new Error(
      "Session's session description handler not instance of SessionDescriptionHandler.",
    );
  }

  const { peerConnection } = sessionDescriptionHandler;
  if (!peerConnection) {
    throw new Error('Peer connection closed.');
  }

  peerConnection.getSenders().forEach(sender => {
    if (sender.track) {
      if (sender.track.kind === 'video') {
        sender.track.enabled = enable;
      }
    }
  });
}

/**
 * Puts Session on hold.
 * @param hold - Hold on if true, off if false.
 */
export async function setHold(hold: boolean, session: Session): Promise<void> {
  if (!session) {
    return Promise.reject(new Error('Session does not exist.'));
  }

  // Just resolve if we are already in correct state
  // if (this.held === hold) {
  //   return Promise.resolve();
  // }

  const sessionDescriptionHandler =
    session.sessionDescriptionHandler as SessionDescriptionHandler;
  if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
    throw new Error(
      "Session's session description handler not instance of SessionDescriptionHandler.",
    );
  }

  const options: SessionInviteOptions = {
    requestDelegate: {
      onAccept: (): void => {
        // this.held = hold;
        enableReceiverTracks(!hold, session);
        enableSenderTracks(!hold, session);
        if (session.delegate && session.delegate) {
          // session.delegate.onCallHold(hold);
        }
      },
      onReject: (): void => {
        console.warn(`[${session.id}] re-invite request was rejected`);
        enableReceiverTracks(!hold, session);
        enableSenderTracks(!hold, session);
        // if (this.delegate && this.delegate.onCallHold) {
        //   this.delegate.onCallHold(this.held);
        // }
      },
    },
  };

  // Session properties used to pass options to the SessionDescriptionHandler:
  //
  // 1) Session.sessionDescriptionHandlerOptions
  //    SDH options for the initial INVITE transaction.
  //    - Used in all cases when handling the initial INVITE transaction as either UAC or UAS.
  //    - May be set directly at anytime.
  //    - May optionally be set via constructor option.
  //    - May optionally be set via options passed to Inviter.invite() or Invitation.accept().
  //
  // 2) Session.sessionDescriptionHandlerOptionsReInvite
  //    SDH options for re-INVITE transactions.
  //    - Used in all cases when handling a re-INVITE transaction as either UAC or UAS.
  //    - May be set directly at anytime.
  //    - May optionally be set via constructor option.
  //    - May optionally be set via options passed to Session.invite().

  const sessionDescriptionHandlerOptions =
    session.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions;
  sessionDescriptionHandlerOptions.hold = hold;
  session.sessionDescriptionHandlerOptionsReInvite =
    sessionDescriptionHandlerOptions;

  // Send re-INVITE
  return session
    .invite(options)
    .then(() => {
      // preemptively enable/disable tracks
      enableReceiverTracks(!hold, session);
      enableSenderTracks(!hold, session);
    })
    .catch((error: Error) => {
      if (error instanceof RequestPendingError) {
        console.error(`[${session.id}] A hold request is already in progress.`);
      }
      throw error;
    });
}

// export function setMuteCall(mute: boolean): void {
//   const constraints = { video: false, audio: true };

//   navigator.mediaDevices
//     .getUserMedia(constraints)
//     .then(stream => {
//       stream.getTracks().forEach(track => {
//         console.log('TRACK', track);
//         track.enabled = false;
//       });
//     })
//     .catch(e => console.error('TRACK', e));
// }

export function getLocalStream() {
  // Different query options:
  // navigator.permissions.query({ name: 'microphone' });
  // navigator.permissions.query({ name: 'notifications' });
  navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
    // @ts-ignore
    displaySurface: 'monitor',
  });
  // Example:
  // navigator.permissions.query({ name: 'microphone' }).then(function (result) {
  //   if (result.state === 'granted') {
  //     // Access granted
  //   } else if (result.state === 'prompt') {
  //     // Access has not been granted
  //   }
  // });
}

// export function selectMedia() {
//   microsoftTeams.media.selectMedia(
//     { maxMediaCount: 1, mediaType: microsoftTeams.media.MediaType.Audio },
//     (
//       error: microsoftTeams.SdkError,
//       attachments: microsoftTeams.media.Media[],
//     ) => {
//       // If there's any error, an alert shows the error message/code
//       if (error) {
//         if (error.message) {
//           alert(` ErrorCode: ${error.errorCode}${error.message}`);
//         } else {
//           alert(` ErrorCode: ${error.errorCode}`);
//         }
//       }

//       if (attachments) {
//         // taking the first attachment
//         const audioResult = attachments[0];

//         // setting audio string which can be used in Video tag
//         const audioData = `data:${audioResult.mimeType};base64,${audioResult.preview}`;
//       }
//     },
//   );
// }

function makeAudioOnlyStreamFromExistingStream(stream: MediaStream) {
  const audioStream = stream.clone();
  const videoTracks = audioStream.getVideoTracks();
  for (let i = 0, len = videoTracks.length; i < len; i++) {
    audioStream.removeTrack(videoTracks[i]);
  }
  console.log(
    'created audio only stream, original stream tracks: ',
    stream.getTracks(),
  );
  console.log(
    'created audio only stream, new stream tracks: ',
    audioStream.getTracks(),
  );
  return audioStream;
}

export function makeVideoOnlyStreamFromExistingStream(stream: MediaStream) {
  const videoStream = stream.clone();
  const audioTracks = videoStream.getAudioTracks();
  for (let i = 0, len = audioTracks.length; i < len; i++) {
    videoStream.removeTrack(audioTracks[i]);
  }
  console.log(
    'created video only stream, original stream tracks: ',
    stream.getTracks(),
  );
  console.log(
    'created video only stream, new stream tracks: ',
    videoStream.getTracks(),
  );
  return videoStream;
}
