import short from 'short-uuid';
import ChatType from '../enums/chatTypes';
import DeliverStatus from '../enums/deliverStatus';
import ReadStatus from '../enums/readStatus';
import IChat from '../interfaces/chat';
import { IConferenceChatResponse } from '../interfaces/conference';
import IMessage from '../interfaces/message';
import Chat from './chat/chatcommunicator';

import {
  SubscriptionCallBack,
  ChatType as XMPPChatType,
  SendMessageCallback,
} from './chat/chatTypes';
import { Events, FileMessage, Message, Presence } from './chat/types/types';

export interface SquadChatCommunicator {
  chat: Chat;
  nextSubscriptionId: number;
  subscriptions: Map<number, SubscriptionCallBack>;
  xmppDomain: IConferenceChatResponse;
}

export class SquadChatCommunicator {
  private settings: IConferenceChatResponse;

  private username: string;

  constructor(settings: IConferenceChatResponse, username: string) {
    this.settings = settings;
    this.username = username
      .split('<squad>')[0]
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    this.nextSubscriptionId = 0;
    this.subscriptions = new Map<number, SubscriptionCallBack>();
  }

  public async makeChat(subscribeFunction: SubscriptionCallBack): Promise<any> {
    this.subscribe(subscribeFunction);
    const options = {
      resource: `squad.web_${this.username
        .split(' ')
        .join('_')}_${short().generate()}`,
      service: `wss://${this.settings.host
        .replace('https://', '')
        .replace('http://', '')}:5280/websocket`,
      username: this.username.split(' ').join('_'),
      domain: this.settings.host.replace('https://', '').replace('http://', ''),
      password: 'citrus',
    };

    const chat = new Chat(options);
    if (this.chat) {
      try {
        this.chat.client.stop();
        this.chat = chat;
        this.subscribeChatEvents(chat);
        return chat.client.start();
      } catch {
        return chat.client.start();
      }
    }
    this.chat = chat;
    this.subscribeChatEvents(chat);
    return chat.client.start();
  }

  public subscribeChatEvents(chat: Chat): void {
    chat.on(Events.MESSAGE, this.onMessage);
    chat.on(Events.PRESENCE, this.onPresence);
    chat.on(Events.ONLINE, () => this.notify(Events.ONLINE, null));
    chat.on(Events.CLOSE, () => this.notify(Events.CLOSE, null));
    chat.on(Events.OFFLINE, () => this.notify(Events.OFFLINE, null));
    chat.on(Events.RECEIVED, data => this.notify(Events.RECEIVED, data));
    chat.on(Events.DISPLAYED, data => this.notify(Events.DISPLAYED, data));
    chat.on(Events.COMPOSING, data => this.notify(Events.COMPOSING, data));
    chat.on(Events.ACTIVE, data => this.notify(Events.ACTIVE, data));
    chat.on(Events.RECONNECTING, () => this.notify(Events.RECONNECTING, null));
    chat.on(Events.RECONNECTED, () => this.notify(Events.RECONNECTED, null));
  }

  public subscribe(subscribeCallback: SubscriptionCallBack): void {
    this.subscriptions.set(this.nextSubscriptionId, subscribeCallback);
    this.nextSubscriptionId += 1;
  }

  public notify(event: string, data: any): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(subscribeCallback => {
        if (subscribeCallback) subscribeCallback(event, data);
      });
    }
  }

  public isUrl = (str: string): boolean => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    return !!str?.match(regex);
  };

  public getFileUrlStr = (
    fileParams: any,
    msg: Message | FileMessage,
  ): string => {
    return 'fileUrl' in msg && fileParams.isFile
      ? (msg as FileMessage).fileUrl
      : fileParams.isFile
      ? msg.message
      : '';
  };

  // var text = 'Find me at http://www.example.com and also at http://stackoverflow.com';
  // var html = urlify(text);

  public onMessage = (msg: Message | FileMessage): void => {
    const fileParams = {
      isFile: 'fileUrl' in msg,
      isImage: false,
      isVideo: false,
      isAudio: false,
    };
    if (fileParams.isFile) {
      fileParams.isImage = /\.(jpe?g|png|gif|ico)$/i.test(
        (msg as FileMessage).fileUrl,
      );
      fileParams.isVideo = /\.(mp4|avi|mov)$/i.test(
        (msg as FileMessage).fileUrl,
      );
      fileParams.isAudio = /\.(ogg|mp3|wav|m4a|webm)$/i.test(
        (msg as FileMessage).fileUrl,
      );
    }
    if (this.isUrl(msg.message)) {
      const splittedMsg = msg.message.split('/');
      const extension = splittedMsg[splittedMsg.length - 1];
      if (extension.includes('.') && splittedMsg.length > 1) {
        fileParams.isFile = true;
        fileParams.isImage = /\.(jpe?g|png|gif|ico)$/i.test(msg.message);
        fileParams.isVideo = /\.(mp4|avi|mov)$/i.test(msg.message);
        fileParams.isAudio = /\.(ogg|mp3|wav|m4a|webm)$/i.test(msg.message);
      }
    }
    const isSentByMe =
      msg.from.split('/')[1] === this.username ||
      msg.from.split('@')[0] === this.username;

    const message: IMessage = {
      id: msg.id,
      userName: this.username,
      userProfilePicture: undefined,
      message: msg.message || (msg as FileMessage).fileUrl,
      time: msg.sent_at,
      isFileMessage:
        fileParams.isFile &&
        !fileParams.isImage &&
        !fileParams.isVideo &&
        !fileParams.isAudio,
      isImageMessage: fileParams.isImage,
      isVideoMessage: fileParams.isVideo,
      isAudioMessage: fileParams.isAudio,
      fileUrl: this.getFileUrlStr(fileParams, msg),
      toUser:
        isSentByMe && msg.from.includes('@conference.')
          ? msg.from.split('/')[0]
          : msg.to,
      fromUser: msg.from.split('/')[1],
      // fromUser: this.username,
      deliverStatus: DeliverStatus.DELIVERED,
      readStatus: ReadStatus.UNREAD,
      deliverTimestamp: new Date().toISOString(),
      readTimestamp: '',
      content: JSON.stringify({
        reply_msg: msg.reply_msg,
        reply_msg_id: msg.reply_msg_id,
        reply_to: msg.reply_to,
      }),
      contentType: (msg as FileMessage).fileUrl || 'text',
      isSentByMe,
      isReplyMessage: !!msg.reply_msg,
      isForwardMessage: !!msg.reply_to && !msg.reply_msg,
      replyedMessage: msg.reply_msg,
      replyedMessageTo: 'Participant',
      replyedMessageId: msg.reply_msg_id,
    };

    this.notify(Events.MESSAGE, message);
  };

  public onPresence = (presence: Presence): void => {
    this.notify(Events.PRESENCE, presence);
  };

  public sendMessage = (
    chat: IChat,
    text: string,
    callback: SendMessageCallback,
  ): void => {
    this.chat.sendMessage(
      chat.jid,
      chat.chatType === ChatType.USER
        ? XMPPChatType.CHAT
        : XMPPChatType.GROUPCHAT,
      text,
      callback,
    );
  };

  public sendFiles = (chat: IChat, files: File[], cb: any): void => {
    files.forEach(file => {
      this.chat.sendFile(
        chat.jid,
        chat.jid.includes('@conference.')
          ? XMPPChatType.GROUPCHAT
          : XMPPChatType.CHAT,
        file,
        cb,
      );
    });
  };

  public joinGroup(to: string): void {
    this.chat.joinRoom(to);
  }

  public changeStatus(status: string): void {
    this.chat.sendPresence(status);
  }

  public removeSubscription(id: number): void {
    this.subscriptions.delete(id);
  }

  public removeAllSubscription(): void {
    this.subscriptions = new Map<number, SubscriptionCallBack>();
  }

  public getXmmppDomain(): string {
    return this.settings.host.replace('https://', '').replace('http://', '');
  }
}
