import { t } from 'i18next';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Session } from 'sip.js';
import { Socket } from 'socket.io-client';
import { parseName } from '../helpers/parseName';
import { IParticipantItem, IParticipantState } from '../interfaces/participant';
import { useAuth } from './auth';
import { useSip } from './sip';

import audioIn from '../assets/audio/getIn.mp3';
import audioOut from '../assets/audio/goOut.mp3';
import { User } from '../helpers/randomIdParse';

export const commands = {
  tmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tmute ${memberId}`,
  tvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tvmute ${memberId}`,
  mute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} mute ${memberId}`,
  changeDevice: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  changeDeviceMic: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  changeDeviceSpeaker: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  vmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vmute ${memberId}`,
  unmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unmute ${memberId}`,
  unvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unvmute ${memberId}`,
  kick: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} kick ${memberId}`,
  kill: (conferenceId: string, sessionUser: Session): string =>
    `conference ${conferenceId} uuid_kill ${sessionUser}`,
  vidFloor: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vid-floor ${memberId} force`,
  muteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} mute non_moderator`,
  vmuteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} vmute non_moderator`,
  listLayouts: (conferenceId: string): string =>
    `conference ${conferenceId} vid-layout list`,
  setLayout: (conferenceId: string, layoutName: string): string =>
    `conference ${conferenceId} vid-layout ${layoutName}`,
  setPresentation: (conferenceId: string, memberId: string): string =>
    `conference ${conferenceId} vid-res-id ${memberId} presenter`,
  setPresentationClear: (conferenceId: string, memberId: string): string =>
    `conference ${conferenceId} vid-res-id ${memberId} clear`,
};

export type Commands =
  | 'tmute'
  | 'tvmute'
  | 'mute'
  | 'changeDevice'
  | 'changeDeviceMic'
  | 'changeDeviceSpeaker'
  | 'vmute'
  | 'unmute'
  | 'unvmute'
  | 'muteNonMod'
  | 'vmuteNonMod'
  | 'kick'
  | 'vidFloor'
  | 'listLayouts'
  | 'setLayout'
  | 'requestSpeak'
  | 'setPresentation'
  | 'setPresentationClear'
  | 'getMemberId'
  | 'kill';

export enum ConferenceEvents {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  REQUEST_SPEAK = 'REQUEST_SPEAK',
}

export interface ConferenceEvent {
  sip_server_ip: string;
  Caller_Name: string;
  layout: string;
  members: ConferenceEventData[];
}
export interface RequestSpeakEvent {
  conferenceId: string;
  command: string;
  userId: string;
  userName: string;
}

export interface ConferenceEventData {
  Caller_Name: string;
  Conference_Name: string;
  Caller_Caller_ID_Name: string;
  Video: boolean;
  Hear: boolean;
  See: boolean;
  Speak: boolean;
  Talking: boolean;
  Floor: boolean;
  'Mute-Detect': boolean;
  Hold: boolean;
  'Member-ID': number;
  'Member-Type': 'moderator' | 'participant';
  'Member-Ghost': boolean;
  Action:
    | 'add-member'
    | 'floor-change'
    | 'video-floor-change'
    | 'mute-member'
    | 'unmute-member'
    | 'vmute-member'
    | 'unvmute-member'
    | 'start-talking'
    | 'stop-talking'
    | 'del-member';
}

interface EventCallback {
  (
    event: ConferenceEvents,
    data: ConferenceEvent | RequestSpeakEvent[],
    conferenceId?: string,
  ): void;
}
interface IEventsProps {
  conferenceId: string;
  eventUrl?: string;
}

interface Props {
  children: React.ReactNode;
  socket: Socket;
  conferenceId: string;
}
interface RemovedProps {
  conferenceId: string;
  memberId: string;
}

interface SocketContextState {
  socket: Socket;
  audio: HTMLAudioElement | null;
  setAudio: Dispatch<SetStateAction<HTMLAudioElement | null>>;
  participantsInCall: string;
  setParticipantsInCall: Dispatch<SetStateAction<string>>;
  usersWhoseWantToSpeak: RequestSpeakEvent[];
  setUsersWhoseWantToSpeak: Dispatch<SetStateAction<RequestSpeakEvent[]>>;
  participantsState: IParticipantState[];
  setParticipantsState: Dispatch<SetStateAction<IParticipantState[]>>;
  layoutString: string;
  setLayoutString: Dispatch<SetStateAction<string>>;
  userThatIsSharing: string | undefined;
  setUserThatIsSharing: Dispatch<SetStateAction<string | undefined>>;
  isModerator: boolean;
  setIsModerator: Dispatch<SetStateAction<boolean>>;
  myMemberName: string;
  setMyMemberName: Dispatch<SetStateAction<string>>;
  myMemberId: string;
  setMyMemberId: Dispatch<SetStateAction<string>>;
  isMuteVideo: boolean;
  setIsMuteVideo: Dispatch<SetStateAction<boolean>>;
  isMuteMic: boolean;
  setIsMuteMic: Dispatch<SetStateAction<boolean>>;
  hasSomeOneSharing: boolean;
  setHasSomeOneSharing: Dispatch<SetStateAction<boolean>>;
  participants: IParticipantItem[];
  setParticipants: Dispatch<SetStateAction<IParticipantItem[]>>;
  sendCommand(
    conferenceId: string,
    command: Commands,
    extraParam?: string | Session | User,
  ): void;
}

const SocketContext = createContext<SocketContextState>(
  {} as SocketContextState,
);

export const SocketProvider = ({
  children,
  socket,
  conferenceId,
}: Props): JSX.Element => {
  const { user, setUser } = useAuth();
  const {
    setLoading,
    isSecond,
    hasAudio,
    hasVideo,
    setOnScreenShare,
    endCall,
    sessions,
    setHasIremoved,
    onScreenShare,
    setCloseSharedScreen,
    setSessionsScharedScreeen,
    sessionsScharedScreeen,
    micUnaviable,
    camUnaviable,
  } = useSip();
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [participantsInCall, setParticipantsInCall] = useState(
    'Waiting for participants',
  );
  const [usersWhoseWantToSpeak, setUsersWhoseWantToSpeak] = useState<
    RequestSpeakEvent[]
  >([]);
  const [participantsState, setParticipantsState] = useState<
    IParticipantState[]
  >([]);
  const [layoutString, setLayoutString] = useState<string>('group:grid');
  const [userThatIsSharing, setUserThatIsSharing] = useState<
    string | undefined
  >(undefined);
  const [isModerator, setIsModerator] = useState<boolean>(false);
  const [myMemberName, setMyMemberName] = useState<string>('');
  const [myMemberId, setMyMemberId] = useState<string>('');
  const [isMuteVideo, setIsMuteVideo] = useState<boolean>(false);
  const [isMuteMic, setIsMuteMic] = useState<boolean>(false);
  const [hasSomeOneSharing, setHasSomeOneSharing] = useState<boolean>(false);
  const [participants, setParticipants] = useState<IParticipantItem[]>([]);

  const singInSocket: EventCallback = useCallback((socketEvent, data) => {
    if (socketEvent === ConferenceEvents.REQUEST_SPEAK) {
      data = data as RequestSpeakEvent[];
    } else {
      const participantsList: string[] = [];
      data = data as ConferenceEvent;
      data?.members?.forEach(member => {
        participantsList.push(member.Caller_Caller_ID_Name);
      });

      switch (participantsList.length) {
        case 0:
          setParticipantsInCall('Waiting for participants');
          break;
        case 1:
          setParticipantsInCall(
            `${parseName(participantsList[0])} ${t('is at the meeting.')}`,
          );
          break;
        case 2:
          setParticipantsInCall(
            `${participantsList
              .map(n => parseName(n))
              .join(t(' and ') as string)} ${t('is at the meeting.')}`,
          );
          break;

        default:
          setParticipantsInCall(
            `${participantsList.map(n => parseName(n)).join(', ')} ${t(
              'is at the meeting.',
            )}`,
          );
          break;
      }
    }
  }, []);

  const conferenceSocket: EventCallback = useCallback(
    (socketEvent, data, conferenceId) => {
      if (isSecond) {
        if (socketEvent === ConferenceEvents.REQUEST_SPEAK) {
          data = data as RequestSpeakEvent[];
          // console.log('LEVANTAR_MAO', data, conferenceId);
          setUsersWhoseWantToSpeak(
            data.filter(rq => rq.conferenceId === conferenceId),
          );
        } else {
          const participantsList: IParticipantItem[] = [];
          let auxState: IParticipantState[] = [];
          // conference dcfa9de6-3404-4684-b8de-b788b8acfb1b vid-res-id 45 presenter
          setParticipantsState(state => {
            auxState = state;
            return state;
          });
          data = data as ConferenceEvent;
          setLayoutString(data?.layout || 'group:grid');
          let hasSharing = false;
          data?.members?.forEach(member => {
            participantsList.push({
              camera: member.See ? 'on' : 'off',
              mic: member.Speak ? 'on' : 'off',
              name: member.Caller_Caller_ID_Name,
              talking: member.Talking,
              screenShare: hasSomeOneSharing
                ? member.Floor
                  ? 'on'
                  : 'off'
                : 'off',
              status: 'on',
              memberId: member['Member-ID'],
              moderator: member['Member-Type'] === 'moderator',
              itsNew: false,
            });

            if (member.Caller_Caller_ID_Name.includes('(Screen)')) {
              hasSharing = true;
            }
            const memberScreen = member.Caller_Caller_ID_Name.replace(
              '(Screen)',
              '',
            );
            if (
              member.Caller_Caller_ID_Name.includes('(Screen)') &&
              memberScreen.includes(user?.name as string)
            ) {
              setOnScreenShare(true);
              if (member.Caller_Caller_ID_Name.includes(user?.name as string)) {
                setUserThatIsSharing(member.Caller_Caller_ID_Name);
              }
            } else {
              // setOnScreenShare(false);
              setUserThatIsSharing(undefined);
            }

            if (
              (user?.name.split('<squad>')[1] as string) ===
              member.Caller_Caller_ID_Name.split('<squad>')[1]
            ) {
              setIsModerator(member['Member-Type'] === 'moderator');
              setMyMemberName(member.Caller_Caller_ID_Name);
              if (isSecond) {
                setMyMemberId(state => {
                  const aux = parseInt(state, 10);
                  if (aux < member['Member-ID'] || state === '') {
                    return String(member['Member-ID']);
                  }
                  return state;
                });
              }

              switch (member.Action) {
                case 'mute-member':
                  setIsMuteMic(true);
                  break;
                case 'unmute-member':
                  setIsMuteMic(false);
                  break;
                case 'vmute-member':
                  setIsMuteVideo(true);
                  break;
                case 'unvmute-member':
                  setIsMuteVideo(false);
                  break;
                default:
                  break;
              }

              if (member.Action === 'mute-member') {
                setIsMuteMic(true);
              }
            }
          });

          const hasIsharing = data?.members?.find(
            aux =>
              aux.Caller_Caller_ID_Name.includes('(Screen)') &&
              aux.Caller_Caller_ID_Name.includes(user?.name as string),
          );
          if (onScreenShare && !hasIsharing) {
            if (sessionsScharedScreeen) {
              setOnScreenShare(false);
              setCloseSharedScreen(false);
              endCall(sessionsScharedScreeen.id);
              setSessionsScharedScreeen(undefined);
              setUserThatIsSharing(undefined);
            }
          }

          setHasSomeOneSharing(hasSharing);
          let participantsNew: IParticipantItem[] = participantsList;
          // eslint-disable-next-line no-restricted-syntax
          for (const element of participantsList) {
            let participantLocal = element;
            if (auxState.length > 0) {
              const member = auxState.find(
                aux =>
                  aux.name === element.name &&
                  aux.memberId !== element.memberId,
              );
              const member2 = auxState.find(aux => aux.name === element.name);
              if (member) {
                if (member.itsFirst < 2) {
                  member.itsFirst += 1;
                  member.itsNew = true;
                  participantLocal = {
                    ...participantLocal,
                    itsNew: true,
                  };

                  if (member.name !== user?.name) {
                    try {
                      setAudio(state => {
                        if (state) {
                          state.src = audioIn;
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          state.id = 'ringAudio';
                          state.loop = false;
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          state.pause();
                          state.play().catch(err => console.log('err', err));
                        } else {
                          const audioAux = new Audio();
                          audioAux.src = audioIn;
                          audioAux.id = 'ringAudio';
                          audioAux.loop = false;
                          audioAux.pause();
                          audioAux.play().catch(err => console.log('err', err));
                          return audioAux;
                        }
                        return state;
                      });
                    } catch (error) {
                      console.log('Error ao tocar audio', error);
                    }
                    setLoading(false);
                  }
                }
                auxState = [
                  ...auxState.filter(
                    aux =>
                      aux.name !== element.name &&
                      aux.memberId !== element.memberId,
                  ),
                  member,
                ];
              } else if (!member2) {
                const memberNew = {
                  memberId: element.memberId,
                  itsFirst: 1,
                  itsNew: false,
                  name: element.name,
                };
                participantLocal.itsNew = false;
                auxState = [
                  ...auxState.filter(aux => aux.name !== memberNew.name),
                  memberNew,
                ];
              }
            } else {
              const member = {
                memberId: element.memberId,
                itsFirst: 1,
                itsNew: undefined,
                name: element.name,
              };
              element.itsNew = true;
              auxState = [...auxState, member];
              if (!isSecond) {
                const itsMe = participantsNew.find(
                  state => state.name === user?.name,
                );
                if (itsMe) {
                  itsMe.camera = !hasVideo ? 'on' : 'off';
                  itsMe.mic = !hasAudio ? 'on' : 'off';
                  participantsNew = [
                    ...participantsNew.filter(aux => aux.name !== itsMe.name),
                    itsMe,
                  ];
                }
              }
            }
            participantsNew = [
              ...participantsNew.filter(
                aux => aux.name !== participantLocal.name,
              ),
              participantLocal,
            ];
          }

          // para remover o que saiu da chamada

          // eslint-disable-next-line no-restricted-syntax
          for (const element of auxState) {
            const hasMember = participantsList.find(
              aux => aux.name === element.name,
            );
            if (!hasMember) {
              auxState = auxState.filter(aux => aux.name !== element.name);
            } else if (
              element.itsFirst === 2 &&
              element.name === hasMember.name
            ) {
              participantsNew = [
                ...participantsNew.filter(aux => aux.name !== element.name),
                {
                  ...hasMember,
                  itsNew: true,
                },
              ];
            } else {
              participantsNew = [
                ...participantsNew.filter(aux => aux.name !== element.name),
                {
                  ...hasMember,
                  itsNew: true,
                },
              ];
            }
          }

          setParticipantsState(state => {
            if (state.length > auxState.length) {
              if (audio) {
                try {
                  audio.src = audioOut;
                  audio.id = 'beepAudio';
                  audio.loop = false;
                  audio.load();
                  audio.muted = true;
                  audio.autoplay = true;
                  audio.setAttribute('playsInline', `true`);
                  audio.pause();
                  audio.muted = false;
                  audio
                    .play()
                    .catch(err => console.log('Error play audio', err));
                } catch (error) {
                  console.log('Error ao tocar audio', error);
                }
              }
            }
            return auxState;
          });
          // member.Caller_Caller_ID_Name.split('<squad>')[1]
          // console.log('setParticipants', participantsNew);
          setParticipants(participantsNew);
        }
      }
    },
    [
      isSecond,
      hasSomeOneSharing,
      user?.name,
      setOnScreenShare,
      setLoading,
      hasVideo,
      hasAudio,
      audio,
    ],
  );

  const eventsOn = useCallback(
    (conferenceId: string): void => {
      if (socket && conferenceId) {
        socket.on('connect', () => {
          console.log('socketContext ON');
          socket?.emit('enterRoom', conferenceId?.split('@')[0]);
        });
        socket.on('update', (data: string | ConferenceEvent) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          singInSocket(ConferenceEvents.UPDATE, data as ConferenceEvent);
          conferenceSocket(
            ConferenceEvents.UPDATE,
            data as ConferenceEvent,
            conferenceId,
          );
        });
        socket.on('enterRoom', (data: any) => {
          console.log('enterRoom', data);
        });
        socket.on('removeRoom', (data: RemovedProps) => {
          if (
            conferenceId === data.conferenceId &&
            myMemberId === data.memberId.toString() &&
            isSecond
          ) {
            setHasIremoved(true);
            endCall(sessions[0].id);
          }
        });
        socket.on('getMemberId', (data: any) => {
          if (data)
            setUser((user: User | undefined) => {
              if (user)
                return {
                  ...user,
                  memberId: data,
                };
              return user;
            });
          // console.log('getMemberId', data);
        });
        socket.on('exitRoom', (data: any) => {
          console.log('exitRoom ', data);
        });
        socket.on('requestSpeak', (data: string | RequestSpeakEvent[]) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          conferenceSocket(
            ConferenceEvents.REQUEST_SPEAK,
            data as RequestSpeakEvent[],
            conferenceId,
          );
        });
      }
    },
    [
      socket,
      singInSocket,
      conferenceSocket,
      sessions,
      myMemberId,
      isSecond,
      endCall,
      setUser,
    ],
  );

  const eventsOff = useCallback(
    (conferenceId: string): void => {
      if (socket && conferenceId) {
        socket.off('connect', () => {
          socket?.emit('enterRoom', conferenceId?.split('@')[0]);
        });
        socket.off('update', (data: string | ConferenceEvent) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          singInSocket(ConferenceEvents.UPDATE, data as ConferenceEvent);
          conferenceSocket(ConferenceEvents.UPDATE, data as ConferenceEvent);
        });
        socket.off('enterRoom', (data: any) => {
          console.log('enterRoom', data);
        });
        socket.off('exitRoom', (data: any) => {
          console.log('exitRoom ', data);
        });
        socket.off('requestSpeak', (data: string | RequestSpeakEvent[]) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          conferenceSocket(
            ConferenceEvents.REQUEST_SPEAK,
            data as RequestSpeakEvent[],
          );
        });
      }
    },
    [conferenceSocket, singInSocket, socket],
  );

  const sendCommand = useCallback(
    (
      conferenceId: string,
      command: Commands,
      extraParam?: string | Session | User,
    ): void => {
      if (command === 'requestSpeak') {
        // console.log('NCOMAND------>');
        extraParam = extraParam as User;
        socket?.emit('update', {
          conferenceId,
          command: 'requestSpeak',
          userId: extraParam?.id,
          userName: extraParam?.name,
        });
      } else if (command === 'getMemberId') {
        socket?.emit('getMemberId', conferenceId?.split('@')[0], extraParam);
      } else {
        const mountCommand = commands[command];
        let commandStr;
        if (
          [
            'tmute',
            'tvmute',
            'kick',
            'vidFloor',
            'setLayout',
            'setPresentation',
            'setPresentationClear',
            'kill',
          ].includes(command) &&
          extraParam
        ) {
          commandStr = mountCommand(conferenceId, extraParam as never);
        } else {
          commandStr = mountCommand(conferenceId, null as never);
        }
        // console.log('COMAND------>', commandStr);
        socket?.emit('update', commandStr);
      }
    },
    [socket],
  );

  const disconnect = useCallback((): void => {
    socket?.disconnect();
    socket?.close();
  }, [socket]);

  useEffect(() => {
    // console.log('socketContext eventsOff, eventsOn', conferenceId);
    eventsOn(conferenceId);
    return eventsOff(conferenceId);
  }, [conferenceId, eventsOff, eventsOn]);

  const socketValue = useMemo(() => {
    return {
      socket,
      audio,
      setAudio,
      participantsInCall,
      setParticipantsInCall,
      usersWhoseWantToSpeak,
      setUsersWhoseWantToSpeak,
      participantsState,
      setParticipantsState,
      layoutString,
      setLayoutString,
      userThatIsSharing,
      setUserThatIsSharing,
      isModerator,
      setIsModerator,
      myMemberName,
      setMyMemberName,
      myMemberId,
      setMyMemberId,
      isMuteVideo,
      setIsMuteVideo,
      isMuteMic,
      setIsMuteMic,
      hasSomeOneSharing,
      setHasSomeOneSharing,
      participants,
      setParticipants,
      sendCommand,
    };
  }, [
    socket,
    audio,
    setAudio,
    participantsInCall,
    setParticipantsInCall,
    usersWhoseWantToSpeak,
    setUsersWhoseWantToSpeak,
    participantsState,
    setParticipantsState,
    layoutString,
    setLayoutString,
    userThatIsSharing,
    setUserThatIsSharing,
    isModerator,
    setIsModerator,
    myMemberName,
    setMyMemberName,
    myMemberId,
    setMyMemberId,
    isMuteVideo,
    setIsMuteVideo,
    isMuteMic,
    setIsMuteMic,
    hasSomeOneSharing,
    setHasSomeOneSharing,
    participants,
    setParticipants,
    sendCommand,
  ]);

  return (
    <SocketContext.Provider value={socketValue}>
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket(): SocketContextState {
  return useContext(SocketContext);
}
