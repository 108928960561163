/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { RiEarthLine } from 'react-icons/ri';
import i18n from '../i18n';
import brazil from '../assets/images/flags/brazil.png';
import usa from '../assets/images/flags/us.jpg';
import './styles.scss';

const DropdownLanguage = () => {
  const [updateSettings, setUpdateSettings] = useState<boolean>(false);
  const [isOpenDropdownLanguage, setIsOPenDropdownLanguage] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const changeLanguageSystem = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      setUpdateSettings(true);
      setIsOPenDropdownLanguage(false);
    },
    [setUpdateSettings],
  );
  const clickDropdownLanguage = useCallback(() => {
    setIsOPenDropdownLanguage(!isOpenDropdownLanguage);
  }, [isOpenDropdownLanguage]);
  return (
    <div className="container-dropdown">
      <RiEarthLine
        className="global-language"
        size={25}
        color="#9fabb7"
        cursor="pointer"
        onClick={clickDropdownLanguage}
      />
      {isOpenDropdownLanguage ? (
        <ul className="dropdown-items">
          <li
            className="items-li-dropdown"
            onClick={() => changeLanguageSystem('pt')}
          >
            <img className="flag" src={brazil} alt="user" height="12" />
            <span className="align-middle">{t('Portuguese')}</span>
          </li>
          <li
            className="items-li-dropdown"
            onClick={() => changeLanguageSystem('eng')}
          >
            <img src={usa} alt="user" className="flag" height="12" />
            <span className="align-middle">{t('English')}</span>
          </li>
        </ul>
      ) : (
        <div />
      )}
    </div>
  );
};
export default DropdownLanguage;
