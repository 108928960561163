/* eslint-disable import/no-extraneous-dependencies */
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { HashRouter } from 'react-router-dom';
import { AppProvider } from './hooks';
import { SipProvider } from './hooks/sip';
import { DevicesProvider } from './hooks/devices';
import Routes from './routes';
import './styles/index.scss';

const options = {
  api_host: 'https://app.posthog.com',
};

const App: React.FC = () => {
  return (
    <AppProvider>
      <PostHogProvider
        apiKey="phc_dyVVuecDOYPRqhMlCnjrkucundJRGCZznRajdwIQwvh"
        options={options}
      >
        <HashRouter>
          <SipProvider>
            <DevicesProvider>
              <Routes />
            </DevicesProvider>
          </SipProvider>
        </HashRouter>
      </PostHogProvider>
    </AppProvider>
  );
};

export default App;
