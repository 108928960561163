import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

import './styles.scss';
import { useAuth } from '../../hooks/auth';
import { parseName } from '../../helpers/parseName';
import DropdownLanguage from '../../DropdownLanguage';

const CardLoadingConference: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <div className="card-loading-conference">
        <span className="title">
          {`${t('Welcome')}, ${parseName(user?.name as string)}.`}
        </span>
        <div className="loader-container">
          <Loader />
        </div>
        <p>{t('You are being redirected to the conference room...')}</p>
        <img src="./logo-squad-transparent.png" alt="squad" />
      </div>
      <div className="icon-dropdown-position">
        <DropdownLanguage />
      </div>
    </>
  );
};

export default CardLoadingConference;
