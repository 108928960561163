/* eslint-disable no-unused-expressions */
import socketIoClient, { Socket } from 'socket.io-client';
import { Session } from 'sip.js';

export const commands = {
  tmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tmute ${memberId}`,
  tvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} tvmute ${memberId}`,
  mute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} mute ${memberId}`,
  changeDevice: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  changeDeviceMic: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  changeDeviceSpeaker: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} ${memberId}`,
  vmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vmute ${memberId}`,
  unmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unmute ${memberId}`,
  unvmute: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} unvmute ${memberId}`,
  kick: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} kick ${memberId}`,
  kill: (conferenceId: string, sessionUser: Session): string =>
    `conference ${conferenceId} uuid_kill ${sessionUser}`,
  vidFloor: (conferenceId: string, memberId: number): string =>
    `conference ${conferenceId} vid-floor ${memberId} force`,
  muteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} mute non_moderator`,
  vmuteNonMod: (conferenceId: string): string =>
    `conference ${conferenceId} vmute non_moderator`,
  listLayouts: (conferenceId: string): string =>
    `conference ${conferenceId} vid-layout list`,
  setLayout: (conferenceId: string, layoutName: string): string =>
    `conference ${conferenceId} vid-layout ${layoutName}`,
  setPresentation: (conferenceId: string, memberId: string): string =>
    `conference ${conferenceId} vid-res-id ${memberId} presenter`,
  setPresentationClear: (conferenceId: string, memberId: string): string =>
    `conference ${conferenceId} vid-res-id ${memberId} clear`,
};
export type Commands =
  | 'tmute'
  | 'tvmute'
  | 'mute'
  | 'changeDevice'
  | 'changeDeviceMic'
  | 'changeDeviceSpeaker'
  | 'vmute'
  | 'unmute'
  | 'unvmute'
  | 'muteNonMod'
  | 'vmuteNonMod'
  | 'kick'
  | 'vidFloor'
  | 'listLayouts'
  | 'setLayout'
  | 'requestSpeak'
  | 'setPresentation'
  | 'setPresentationClear'
  | 'kill';

export enum ConferenceEvents {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  REQUEST_SPEAK = 'REQUEST_SPEAK',
}

export interface ConferenceEvent {
  sip_server_ip: string;
  Caller_Name: string;
  layout: string;
  members: ConferenceEventData[];
}
export interface RequestSpeakEvent {
  conferenceId: string;
  command: string;
  userId: string;
  userName: string;
}
export interface ConferenceEventData {
  Caller_Name: string;
  Conference_Name: string;
  Caller_Caller_ID_Name: string;
  Video: boolean;
  Hear: boolean;
  See: boolean;
  Speak: boolean;
  Talking: boolean;
  Floor: boolean;
  'Mute-Detect': boolean;
  Hold: boolean;
  'Member-ID': number;
  'Member-Type': 'moderator' | 'participant';
  'Member-Ghost': boolean;
  Action:
    | 'add-member'
    | 'floor-change'
    | 'video-floor-change'
    | 'mute-member'
    | 'unmute-member'
    | 'vmute-member'
    | 'unvmute-member'
    | 'start-talking'
    | 'stop-talking'
    | 'del-member';
}

interface EventCallback {
  (event: ConferenceEvents, data: ConferenceEvent | RequestSpeakEvent[]): void;
}
interface IEventsProps {
  conferenceId: string;
  eventUrl?: string;
}
export class SquadEvenstCommunicator {
  public currentSocket: Socket | undefined;

  private eventCallback: EventCallback | undefined;

  private conferenceId: string | undefined;

  public connect(
    { conferenceId, eventUrl }: IEventsProps,
    eventCallback: EventCallback,
  ): void {
    this.eventCallback = eventCallback;
    this.conferenceId = conferenceId;
    this.currentSocket = socketIoClient.io(
      eventUrl || 'https://app.squad.us/',
      {
        upgrade: false,
        transports: ['websocket'],
      },
    );
    // this.currentSocket = socketIoClient.io('http://localhost:3333/');
  }

  public eventsOn(): void {
    if (this.currentSocket && this.conferenceId) {
      this.currentSocket.on('connect', () => {
        this.currentSocket?.emit('enterRoom', this.conferenceId?.split('@')[0]);
      });
      this.currentSocket.on('update', (data: string | ConferenceEvent) => {
        data = typeof data === 'string' ? JSON.parse(data) : data;
        this.eventCallback &&
          this.eventCallback(ConferenceEvents.UPDATE, data as ConferenceEvent);
      });
      this.currentSocket.on('enterRoom', (data: any) => {
        console.log('enterRoom', data);
      });
      this.currentSocket.on('exitRoom', (data: any) => {
        console.log('exitRoom ', data);
      });
      this.currentSocket.on(
        'requestSpeak',
        (data: string | RequestSpeakEvent[]) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          this.eventCallback &&
            this.eventCallback(
              ConferenceEvents.REQUEST_SPEAK,
              data as RequestSpeakEvent[],
            );
        },
      );
    }
  }

  public eventsOff(): void {
    if (this.currentSocket && this.conferenceId) {
      this.currentSocket.off('connect', () => {
        this.currentSocket?.emit('enterRoom', this.conferenceId?.split('@')[0]);
      });
      this.currentSocket.off('update', (data: string | ConferenceEvent) => {
        data = typeof data === 'string' ? JSON.parse(data) : data;
        this.eventCallback &&
          this.eventCallback(ConferenceEvents.UPDATE, data as ConferenceEvent);
      });
      this.currentSocket.off('enterRoom', (data: any) => {
        console.log('enterRoom', data);
      });
      this.currentSocket.off('exitRoom', (data: any) => {
        console.log('exitRoom ', data);
      });
      this.currentSocket.off(
        'requestSpeak',
        (data: string | RequestSpeakEvent[]) => {
          data = typeof data === 'string' ? JSON.parse(data) : data;
          this.eventCallback &&
            this.eventCallback(
              ConferenceEvents.REQUEST_SPEAK,
              data as RequestSpeakEvent[],
            );
        },
      );
    }
  }

  public updateEventCallback = (eventCB: EventCallback): void => {
    this.eventCallback = eventCB;
  };

  public sendCommand = (
    conferenceId: string,
    command: Commands,
    extraParam?: string | Session,
  ): void => {
    if (command !== 'requestSpeak') {
      const mountCommand = commands[command];
      let commandStr;
      if (
        [
          'tmute',
          'tvmute',
          'kick',
          'vidFloor',
          'setLayout',
          'setPresentation',
          'setPresentationClear',
          'kill',
        ].includes(command) &&
        extraParam
      ) {
        // console.log('TESTE No send command', extraParam);
        commandStr = mountCommand(conferenceId, extraParam as never);
      } else {
        commandStr = mountCommand(conferenceId, null as never);
      }
      // console.log('COMAND------>', commandStr);
      this.currentSocket?.emit('update', commandStr);
    } else {
      // console.log('NCOMAND------>');
      this.currentSocket?.emit('update', {
        conferenceId,
        command: 'requestSpeak',
        userId: extraParam,
        userName: extraParam,
      });
    }
  };

  public disconnect = (): void => {
    this.currentSocket?.disconnect();
    this.currentSocket?.close();
    this.currentSocket = undefined;
  };
}
