/* eslint-disable no-shadow */
export type SignInCredentials = {
  username: string;
  password: string;
};

export type SettingsProps = {
  video: string;
  chat: string;
  sipDomain: string;
  sipPort: number;
  sipUser: string;
  sipPw: string;
  transferProtocol: string;
  sipRegistration: string;
  proxy: string;
  audioCodecOrder: string[];
  videoCodecOrder: string[];
  stunServer: string;
  usePublicIp: string;
  sendKeepAlives: string;
  keepalivePeriod: number;
  webrtcUrl: string;
  webrtcUrl2: string;
  xmppDomain: string;
  xmppPort: number;
  xmppUser: number;
  xmppPw: number;
  sipTransportProtocol: string;
  sipIgnoreTlsCertificate: string;
  xmppTransportProtocol: string;
  xmppIgnoreTlsCertificate: string;
  voicemail: string;
  sipEnableSrtp: string;
  configMap: {
    squad_help_url: string;
  };
};

export type MeProps = {
  id: string;
  name: string;
  nickname: string;
  imId: string;
  contactType: string;
  email: string;
  domainId: string;
  domainName: string;
  departmentId: string;
  department: string;
  privacy: boolean;
  blocked: boolean;
  favorite: boolean;
  admin: boolean;
  added: boolean;
  status: string;
  imageUrl: string;
  extensionId: string;
  extension: number;
  extensions: [
    {
      extensionId: string;
      extension: number;
      domainId: string;
      domainName: string;
      usuarioId: string;
    },
  ];
  numbers: [
    {
      number: number;
    },
  ];
};

export type AuthProps = {
  access_token: string;
  user_id: string;
  user_name: string;
  expires_in: number;
  base_url: string;
};

export type CallHistoryObjectProps = {
  id: string;
  number: string;
  direction: string;
  duration: number;
  date: string;
  hour: string;
  status: string;
};

export type CallHistoryProps = {
  metadata: any;
  calls: CallHistoryObjectProps[] | [];
};

export type ContactObjectProps = {
  id: string;
  name: string;
  imageUrl: string;
  number: string;
  userId: string;
  email: string;
  domainId: string;
  numbers: [{ number: string }];
};
export type ContactsProps = {
  metadata: any;
  contacts: ContactObjectProps[] | [];
};
export type DetailContactsProps = {
  metadata: any;
  contacts: [
    {
      name: string;
      email: string;
      number: string;
      imageUrl: string | null;
    },
  ];
};

export enum StatusCallEnum {
  initial,
  connecting,
  connected,
  hold,
  callActive,
  callEnded,
  idle,
}

export enum StateCallEnum {
  confirmed = 'Confirmed',
  early = 'Early',
}
export enum DirectionCallEnum {
  inbound = 'inbound',
  outbound = 'outbound',
}

// availability	  activity	        Description
// Available	    Available	        Updates the presence session as Available.
// Busy	          InACall	          Updates the presence session as Busy, InACall.
// Busy	          InAConferenceCall	Updates the presence session as Busy, InAConferenceCall.
// Away	          Away	            Updates the presence session as Away.

export enum AvailabilityEnum {
  available = 'Available',
  busy = 'Busy',
  away = 'Away',
}
export enum ActivityEnum {
  available = 'Available',
  inACall = 'InACall',
  inAConferenceCall = 'InAConferenceCall',
  away = 'Away',
}
export type CallDiallerProps = {
  id: string;
  sessionId: string;
  name?: string;
  number: string;
  imageURL?: string | null;
  callDateTime: Date;
  statusAudio?: boolean;
  status: StatusCallEnum;
  optionsCall?: string;
  direction: DirectionCallEnum;
  duration?: number;
  timer?: string;
};

export type MediaLocal = {
  /** The local video media stream is attached to this element. */
  video?: HTMLVideoElement;
};

export type MediaRemote = {
  audio?: HTMLAudioElement;
  video?: HTMLVideoElement;
};

// export type useRefProps = NodeJS.Timeout | undefined | number;

export type TimerProps = {
  callUuid: string;
  timer: string;
};
export type TimerIdProps = {
  id: number;
  callUuid: string;
};

export type ImageCallProps = {
  callId: string;
  imageURL: string | null;
};
