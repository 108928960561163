import { Socket, io } from 'socket.io-client';

const generateSocket = (socketUrl: string): Socket => {
  return io(socketUrl, {
    transports: ['websocket'],
    upgrade: false,
  });
};

export default generateSocket;
