/* eslint-disable react/require-default-props */
import React, { ChangeEvent, useEffect, useRef } from 'react';

import './styles.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  name: string;
  setName(e: ChangeEvent<HTMLInputElement>): void;
  loading?: boolean;
  disabled?: boolean;
  disabledInput?: boolean;
  isSquad?: string | undefined;
}

const InputButtonJoin: React.FC<IProps> = props => {
  const {
    name,
    setName,
    loading = false,
    disabled = false,
    disabledInput = false,
    isSquad,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="input-button-join">
      {isSquad !== 'true' && (
        <input
          disabled={disabledInput}
          ref={inputRef}
          type="text"
          placeholder={t('Enter your name') as string}
          required
          value={name}
          onChange={setName}
        />
      )}
      <button
        type={loading ? 'button' : 'submit'}
        className={`${(loading || disabled) && 'loading'}`}
        disabled={disabled}
      >
        {loading ? <div className="loader" /> : t('Join')}
      </button>
    </div>
  );
};

export default InputButtonJoin;
