/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { RiAndroidFill, RiAppleFill } from 'react-icons/ri';

import Webcam from 'react-webcam';

import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { useAuth } from '../../hooks/auth';

import CallParticipants from '../../components/CallParticipants';
import InputButtonJoin from '../../components/InputButtonJoin';
import MeetConfigMicButton from '../../components/MeetConfigMicButton';
import MeetConfigVideoButton from '../../components/MeetConfigVideoButton';

import DropdownLanguage from '../../DropdownLanguage';
import { parseName } from '../../helpers/parseName';
import { randomIdParse, User } from '../../helpers/randomIdParse';
import { useDevices } from '../../hooks/devices';
import { useSip } from '../../hooks/sip';
import { useSocket } from '../../hooks/socket';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';
import { camResCheck } from '../../services/utils/camResCheck';
import { IQueryParameters } from '../../services/utils/stringUtils';
import './styles.scss';

interface IProps {
  signInAction(): void;
  addChatData(data: SquadChatCommunicator): void;
  conferenceId: string;
  pin: string;
  params: IQueryParameters | undefined;
}

const SignIn: React.FC<IProps> = props => {
  const { signInAction, addChatData, conferenceId, pin, params } = props;
  const {
    chatConference,
    conferenceTitle,
    currentAudioDevice,
    currentSpeakerDevice,
    deviceMic,
    deviceSpeaker,
    deviceVideo,
    eventsSocketUrl,
    loadingButton,
    mic,
    video,
    voiceConference,
    setMic,
    setVideo,
    setCurrentAudioDevice,
    setLoadingButton,
    setCurrentSpeakerDevice,
  } = useDevices();
  const { signIn, updateDevice } = useAuth();
  const { t } = useTranslation();
  const [user, setUser] = useState<User>(() => {
    if (params?.userName) {
      const parsedSquadUserName = randomIdParse(decodeURI(params.userName));
      localStorage.setItem(
        '@SquadAnnymous:user',
        JSON.stringify(parsedSquadUserName),
      );
      return parsedSquadUserName;
    }
    const user = localStorage.getItem('@SquadAnnymous:user');
    if (user) {
      const generalUserName = JSON.parse(user).name;
      if (!generalUserName.includes('<squad>')) {
        const parsedName = randomIdParse(decodeURI(generalUserName));
        return parsedName.name;
      }
      return JSON.parse(decodeURI(user));
    }
    return {
      name: '',
      id: '',
    };
  });
  const [showVideoConfig, setShowVideoConfig] = useState(false);
  const [showMicConfig, setShowMicConfig] = useState(false);
  const [speackerDevices, setSpeackerDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const { setDeviceMic, setDeviceVideo, setDeviceSpeaker } = useDevices();

  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  const { participantsInCall, socket } = useSocket();

  const [muted, setMuted] = useState(true);
  const {
    setLoading,
    devices,
    setDevices,
    deviceConfig,
    setDeviceConfig,
    setDeviceSizeConfig,
    setInitialState,
    hasAudio,
    setHasAudio,
    hasVideo,
    setHasVideo,
  } = useSip();

  useEffect(() => {
    posthog.capture('singIn-meet', { userId: user.id });
  }, []);

  useEffect(() => {
    setDeviceVideo(devices);
  }, [devices]);

  useEffect(() => {
    setDeviceSpeaker(speackerDevices);
  }, [speackerDevices]);

  const { userAgent } = navigator;
  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );

  const updateUser = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUser(randomIdParse(e.target.value));
  }, []);

  const openVideoConfig = useCallback(() => {
    setShowVideoConfig(!showVideoConfig);
  }, [showVideoConfig]);

  const openMicConfig = useCallback(() => {
    setShowMicConfig(!showMicConfig);
  }, [showMicConfig]);

  const enterConference = useCallback(
    async (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoadingButton(true);
      signIn({ ...user, device: deviceConfig });

      setInitialState({
        video: hasVideo,
        audio: hasAudio,
      });
      if (voiceConference && (chatConference || params?.isSquad === 'true')) {
        setMuted(state => !state);
        if (chatConference) {
          // console.log('Chat conference', chatConference);
          const squadChat = new SquadChatCommunicator(
            chatConference,
            user.name,
          );
          addChatData(squadChat);
        }
        signInAction();
      }
    },
    [
      signIn,
      user,
      deviceConfig,
      setInitialState,
      hasVideo,
      hasAudio,
      voiceConference,
      chatConference,
      params?.isSquad,
      signInAction,
      addChatData,
    ],
  );

  const changeMic = useCallback(() => {
    setDeviceConfig(state => {
      return {
        ...state,
        useAudio: !mic,
      };
    });
    setMic(!mic);
    setHasAudio(state => {
      return !state;
    });
  }, [mic, setHasAudio, setDeviceConfig]);

  const changeAudioDevice = useCallback(
    (audioDevice: string) => {
      const data = {
        ...deviceConfig,
        audioId: audioDevice,
      };

      setDeviceConfig(state => {
        return {
          ...state,
          audioId: audioDevice,
        };
      });
      updateDevice(data);
      setCurrentAudioDevice(audioDevice);
    },

    [deviceConfig, setDeviceConfig, updateDevice],
  );

  const changeSpeakerDevice = useCallback(
    (speakerDevice: string) => {
      const data = {
        ...deviceConfig,
        speakerId: speakerDevice,
      };

      setDeviceConfig(state => {
        return {
          ...state,
          speakerId: speakerDevice,
        };
      });
      updateDevice(data);
      setCurrentSpeakerDevice(speakerDevice);
    },
    [deviceConfig, setDeviceConfig, updateDevice],
  );

  const changeVideo = useCallback(() => {
    setDeviceConfig(state => {
      return {
        ...state,
        useVideo: !video,
      };
    });
    setVideo(!video);
    setHasVideo(state => {
      return !state;
    });
  }, [video, setHasVideo, setDeviceConfig]);

  const changeVideoDevice = useCallback(
    (videoDevice: string) => {
      setDeviceConfig({
        ...deviceConfig,
        videoId: videoDevice,
      });
      camResCheck(videoDevice);
    },
    [deviceConfig, setDeviceConfig],
  );

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      {video && deviceConfig.videoId ? (
        <div className="background">
          <Webcam
            className="myCam"
            videoConstraints={{ deviceId: deviceConfig.videoId }}
          />
        </div>
      ) : (
        <div className="background" />
      )}

      <div className="meet-info">
        <div className="meet-form notAvailableCam">
          <img
            src="./logo-squad-transparent.png"
            alt="squad"
            className="logo"
          />

          {conferenceId === '' || (pin === '' && params?.isSquad !== 'true') ? (
            <span className="title">
              {t(
                'Conference not informed. Please use the link received on invitation.',
              )}
            </span>
          ) : (
            <span className="title">{t(conferenceTitle)}</span>
          )}
          {isMobile ? (
            <div className="links-action">
              <CallParticipants participants={participantsInCall} />
              <a
                className="link-button"
                href={
                  isAndroid
                    ? 'https://play.google.com/store/apps/details?id=com.squadmeet&hl=pt_BR&gl=US'
                    : 'https://apps.apple.com/br/app/squad-meet/id1507938691'
                }
              >
                {isAndroid ? <RiAndroidFill /> : <RiAppleFill />}
                Baixar Squad Meet
              </a>

              <a
                className="link-button"
                href={`squadmeet://domain/app.citrussquad.com/conferenceId/${conferenceId}/pin/${pin}`}
              >
                Abrir no Squad Meet
              </a>
            </div>
          ) : (
            <form onSubmit={enterConference}>
              {conferenceId !== '' &&
                (pin !== '' || params?.isSquad === 'true') && (
                  <>
                    <InputButtonJoin
                      disabledInput={params?.isSquad === 'true'}
                      name={parseName(user.name)}
                      setName={updateUser}
                      loading={loadingButton}
                      isSquad={params?.isSquad}
                    />
                    <CallParticipants participants={participantsInCall} />
                  </>
                )}
              <div className="meet-config">
                <MeetConfigVideoButton
                  video={video}
                  devices={devices}
                  changeVideo={changeVideo}
                  setShowVideoConfig={openVideoConfig}
                  showVideoConfig={showVideoConfig}
                  changeVideoDevice={changeVideoDevice}
                  deviceConfig={deviceConfig}
                />
                <MeetConfigMicButton
                  changeMic={changeMic}
                  mic={mic}
                  micInputDevices={deviceMic}
                  micOutputDevices={deviceSpeaker}
                  setShowMicConfig={openMicConfig}
                  showMicConfig={showMicConfig}
                  changeAudioDevice={changeAudioDevice}
                  changeSpeakerDevice={changeSpeakerDevice}
                  currentAudio={currentAudioDevice}
                  currentSpeaker={currentSpeakerDevice}
                  closeConfig={() => setShowMicConfig(false)}
                  videoActions
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="container-dropdown-language">
        <DropdownLanguage />
      </div>
    </>
  );
};

export default SignIn;
