import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import {
  RiAttachmentLine,
  RiCloseFill,
  RiSendPlane2Fill,
} from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Message from '../Message';

import './styles.scss';

interface IProps {
  closeChat(): void;
  sendMessage(text: string): void;
  sendFile(files: File[]): void;
  messages: IMessageData[];
}

interface IMessageData {
  id: string;
  content: string;
  type: 'my-message' | 'another-message';
  name: string;
  isImageMessage: boolean;
  isFileMessage: boolean;
  date: Date;
}

const Chat: React.FC<IProps> = props => {
  const { closeChat, sendMessage, messages, sendFile } = props;
  const [text, setText] = useState('');
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const onScrollChat = useCallback(() => {
    if (chatBodyRef.current) {
      const xH = chatBodyRef.current.scrollHeight;
      chatBodyRef.current.scrollTo(0, xH);
    }
  }, []);

  const onSendMessage = useCallback(() => {
    if (text.trim() !== '') {
      sendMessage(text);
      setText('');
      textAreaRef.current?.focus();
    }
  }, [sendMessage, text]);

  const onSendMessageEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (text.trim() !== '') {
          sendMessage(text);
          setText('');
          textAreaRef.current?.focus();
        }
      }
    },
    [sendMessage, text],
  );

  const openAttatchments = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [inputFileRef]);

  const onChnageAttatchments = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        sendFile([e.target.files[0]]);
      }
    },
    [sendFile],
  );

  useEffect(() => {
    if (messages) {
      onScrollChat();
    }
    textAreaRef.current?.focus();
  }, [onScrollChat, messages]);

  return (
    <aside className="chat-container">
      <div className="chat-container-header">
        <span>{t('Chat')}</span>
        <RiCloseFill onClick={closeChat} />
      </div>
      <div className="chat-container-body" ref={chatBodyRef}>
        {messages.map(message => (
          <Message
            key={message.id}
            message={message.content}
            type={message.type}
            name={message.name}
            isImage={message.isImageMessage}
            isFile={message.isFileMessage}
            date={message.date}
          />
        ))}
      </div>
      <div className="chat-container-footer">
        <form>
          <textarea
            ref={textAreaRef}
            rows={1}
            placeholder={t('Send message...') as string}
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyPress={onSendMessageEnter}
          />
          {/* <RiAttachmentLine
            className="attachment-button"
            onClick={openAttatchments}
          /> */}
          <button type="button" onClick={onSendMessage}>
            <RiSendPlane2Fill />
          </button>
          <input
            type="file"
            className="input-file"
            ref={inputFileRef}
            onChange={onChnageAttatchments}
          />
        </form>
      </div>
    </aside>
  );
};

export default Chat;
