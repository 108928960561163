/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiErrorWarningLine,
  RiMicLine,
  RiMicOffLine,
  RiVolumeUpLine,
} from 'react-icons/ri';

import { useTranslation } from 'react-i18next';
import { useSip } from '../../hooks/sip';
import './styles.scss';

interface IProps {
  mic: boolean;
  changeMic(): void;
  showMicConfig: boolean;
  micInputDevices: MediaDeviceInfo[] | undefined;
  micOutputDevices: MediaDeviceInfo[] | undefined;
  setShowMicConfig(): void;
  changeSpeakerDevice(deviceId: string): void;
  changeAudioDevice(deviceId: string): void;
  closeConfig(): void;
  currentAudio: string;
  currentSpeaker: string;
  videoActions: boolean;
}

const MeetConfigMicButton: React.FC<IProps> = props => {
  const {
    changeMic,
    showMicConfig,
    mic,
    micInputDevices,
    micOutputDevices,
    setShowMicConfig,
    changeAudioDevice,
    changeSpeakerDevice,
    currentAudio,
    currentSpeaker,
    closeConfig,
    videoActions,
  } = props;
  const containerMicRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { handleHasDevices } = useSip();
  const [isOpen, setIsOpen] = useState(false);

  const onChangeAudioDevice = useCallback(
    (deviceId: string) => {
      changeAudioDevice(deviceId);
      setIsOpen(false);
    },
    [changeAudioDevice],
  );

  const onChangeSpeakerDevice = useCallback(
    (deviceId: string) => {
      changeSpeakerDevice(deviceId);
    },
    [changeSpeakerDevice],
  );

  const handleClick = useCallback(
    e => {
      if (!containerMicRef.current?.contains(e.target)) {
        if (showMicConfig) {
          closeConfig();
        }
      }
    },
    [showMicConfig, closeConfig],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <div className="meet-config-button" ref={containerMicRef}>
      {micInputDevices && handleHasDevices(micInputDevices) ? (
        <>
          <div className="tooltipCustom">
            <button
              className="icon-button meet-config-cam no-found"
              id="on_off_mic"
              type="button"
              disabled
              style={{ cursor: 'default' }}
            >
              <RiMicOffLine size={22} />
            </button>
            <span
              className="actions-button"
              style={{ background: '#ffd000c7', cursor: 'default' }}
            >
              <RiErrorWarningLine />
            </span>
            <span className="tooltiptextCustom">{t('noDeviceMic')}</span>
          </div>
        </>
      ) : (
        <>
          <div className="dropdown bonitin">
            <span
              className="dropdown-button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? (
                <RiArrowDownSLine className="dropdown-arrow" size={22} />
              ) : (
                <RiArrowUpSLine className="dropdown-arrow" size={22} />
              )}
            </span>
            <span
              className={`icon-button ${!mic && 'no-config'} micUpButton`}
              onClick={changeMic}
            >
              {mic ? (
                <RiMicLine style={{ color: '#fff' }} size={22} />
              ) : (
                <RiMicOffLine style={{ color: '#fff' }} size={22} />
              )}
            </span>
            {isOpen && (
              <div className="dropdown-mic">
                <ul className="p-0">
                  <li className="item-section">
                    <RiMicLine style={{ color: '#fff' }} />
                    {t('Microphones')}
                  </li>
                  {micInputDevices &&
                    micInputDevices.map(input => (
                      <li
                        key={input.deviceId}
                        className={`audio-item ${
                          input.deviceId === currentAudio && 'active'
                        }`}
                        onClick={() => onChangeAudioDevice(input.deviceId)}
                      >
                        {input.label}
                      </li>
                    ))}
                  {videoActions && (
                    <>
                      <li className="item-section">
                        <RiVolumeUpLine />
                        {t('Speakers')}
                      </li>
                      {micOutputDevices &&
                        micOutputDevices.map(input => (
                          <li
                            key={input.deviceId}
                            className={`audio-item ${
                              input.deviceId === currentSpeaker && 'active'
                            }`}
                            onClick={() =>
                              onChangeSpeakerDevice(input.deviceId)
                            }
                          >
                            {input.label}
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MeetConfigMicButton;
