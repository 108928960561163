import layouts from '../../../assets/layouts.json';
import { ILayout } from './ILayouts';

export const getLayoutByCamsLightUp = (
  camsLightUp: number,
  group: string,
): string => {
  const groupList = layouts.groups.find(g => g.name === group)?.layout;
  return (
    layouts.layouts
      .filter(l => groupList?.includes(l.name))
      .sort((a, b) => (a.maxParticipants > b.maxParticipants ? 1 : -1))
      .find(layout => layout.maxParticipants >= camsLightUp) as ILayout
  )?.name;
};
