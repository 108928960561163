import layouts from '../../../assets/layouts.json';
import { IImagePosition, ILayout, ILayoutImage } from './ILayouts';

const LayoutsArray = layouts.layouts as ILayout[];

class Layouts {
  private layout!: string;

  private layoutObject!: ILayout;

  private videoHeight!: number;

  private videoWidth!: number;

  private HConst!: number;

  private WConst!: number;

  constructor(layout: string, videoHeight: number, videoWidth: number) {
    this.updateLayout(layout);
    this.updateHeight(videoHeight);
    this.updateWidth(videoWidth);
  }

  updateLayout(newLayout: string): void {
    this.layout = newLayout;
    const newObj = LayoutsArray.find(l => l.name === this.layout);
    if (newObj) this.layoutObject = newObj;
  }

  updateHeight(newHeight: number): void {
    this.videoHeight = newHeight;
    this.HConst = this.videoHeight / 360;
  }

  updateWidth(newWidth: number): void {
    this.videoWidth = newWidth;
    this.WConst = this.videoWidth / 360;
  }

  getLayoutImages(): IImagePosition[] {
    if ((this.layoutObject.image as ILayoutImage).x) {
      const layoutImage = this.layoutObject.image as ILayoutImage;
      return [this.getLayoutImage(layoutImage)];
    }
    const layoutImages = this.layoutObject.image as ILayoutImage[];
    return layoutImages.map(li => this.getLayoutImage(li));
  }

  getLayoutImage(layoutImage: ILayoutImage): IImagePosition {
    return {
      x: Math.round(parseInt(layoutImage.x, 10) * this.WConst),
      y: Math.round(parseInt(layoutImage.y, 10) * this.HConst),
      width: Math.round(parseInt(layoutImage.scale, 10) * this.WConst),
      height: Math.round(
        parseInt(layoutImage.hscale || layoutImage.scale, 10) * this.HConst,
      ),
      resId: layoutImage.reservation_id,
    };
  }
}
export default Layouts;
