import { v4 } from 'uuid';

const randomId = v4();

export interface User {
  name: string;
  id: string;
  memberId?: string;
}

export const randomIdParse = (name: string): User => {
  const divider = '<squad>';
  const parsedName = `${name}${divider}${randomId}`;
  return {
    name: parsedName,
    id: randomId,
  };
};
