import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  RiArrowDownSLine,
  RiArrowDropDownLine,
  RiArrowUpSLine,
  RiCameraLine,
  RiCameraOffLine,
  RiErrorWarningLine,
} from 'react-icons/ri';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { IDeviceConfig } from '../../hooks/auth';
import { useSip } from '../../hooks/sip';

interface IProps {
  video: boolean;
  changeVideo(): void;
  showVideoConfig: boolean;
  devices: MediaDeviceInfo[];
  setShowVideoConfig(): void;
  changeVideoDevice(deviceId: string): void;
  deviceConfig: IDeviceConfig;
}

const MeetConfigVideoButton: React.FC<IProps> = props => {
  const {
    changeVideo,
    showVideoConfig,
    video,
    devices,
    setShowVideoConfig,
    changeVideoDevice,
    deviceConfig,
  } = props;
  const { handleHasDevices } = useSip();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onChangeVideoDevice = useCallback(
    (deviceId: string) => {
      changeVideoDevice(deviceId);
      setIsOpen(false);
      setShowVideoConfig();
    },
    [changeVideoDevice, setShowVideoConfig],
  );

  const handleClick = useCallback(
    e => {
      if (!containerRef.current?.contains(e.target)) {
        if (showVideoConfig) {
          setShowVideoConfig();
        }
      }
    },
    [setShowVideoConfig, showVideoConfig],
  );

  useEffect(() => {
    console.log('weioujfwepoifjwepoifj', isOpen);
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const handleVideoDevices = (devices: MediaDeviceInfo[]) => {
    if (devices.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <div className="meet-config-button" ref={containerRef}>
      {devices && handleHasDevices(devices) ? (
        <>
          <div className="tooltipCustom">
            <button
              className="icon-button meet-config-cam no-found"
              id="on_off_mic"
              type="button"
              disabled
              style={{ cursor: 'default' }}
            >
              <RiCameraOffLine size={22} />
            </button>
            {showVideoConfig && <div className="dropdown-videocam" />}
            <span
              className="actions-button"
              style={{ background: '#ffd000c7', cursor: 'default' }}
            >
              <RiErrorWarningLine />
            </span>
            <span className="tooltiptextCustom">{t('noDeviceVideo')}</span>
          </div>
        </>
      ) : (
        <>
          <div className="dropdown bonitin">
            <span
              className="dropdown-button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? (
                <RiArrowDownSLine className="dropdown-arrow" size={22} />
              ) : (
                <RiArrowUpSLine className="dropdown-arrow" size={22} />
              )}
            </span>
            <span
              className={`icon-button ${!video && 'no-config'} micUpButton`}
              onClick={changeVideo}
            >
              {video ? (
                <RiCameraLine style={{ color: '#fff' }} size={22} />
              ) : (
                <RiCameraOffLine size={22} />
              )}
            </span>
            {isOpen && (
              <div className="dropdown-videocam">
                <ul className="p-0">
                  <li className="item-section">
                    <RiCameraLine
                      style={{ color: '#fff', marginRight: '5px' }}
                      size={22}
                    />
                    {t('Camera')}
                  </li>
                  {devices.map(input => (
                    <li
                      key={input.deviceId}
                      className={`audio-item ${
                        input.deviceId === deviceConfig.videoId && 'active'
                      }`}
                      onClick={() => {
                        onChangeVideoDevice(input.deviceId);
                      }}
                    >
                      {input.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MeetConfigVideoButton;
