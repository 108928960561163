import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CallEnded from '../../containers/CallEnded';
import Conference from '../../containers/Conference';
import LoadingConference from '../../containers/LoadingConference';
import SignIn from '../../containers/SignIn';
import { useSip } from '../../hooks/sip';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';

const Home: React.FC = () => {
  const [chat, setChat] = useState<SquadChatCommunicator>();
  const location = useLocation();
  const [layout, setLayout] = useState(1);
  const { conferenceId, pin, params, hasIremoved } = useSip();

  const onSignIn = useCallback(() => {
    setLayout(3);
  }, []);

  const onEndCall = useCallback(() => {
    setLayout(4);
  }, []);
  const onReturn = useCallback(() => {
    setLayout(1);
  }, []);

  const addChatData = useCallback(async (chatData: SquadChatCommunicator) => {
    setChat(chatData);
  }, []);

  const renderLayout = useCallback(() => {
    switch (layout) {
      case 1:
        return (
          <SignIn
            signInAction={onSignIn}
            conferenceId={conferenceId}
            pin={pin}
            addChatData={addChatData}
            params={params}
          />
        );
      case 2:
        return <LoadingConference />;
      case 3:
        return (
          <Conference
            conferenceId={conferenceId}
            pin={pin}
            chat={chat}
            params={params}
            setLayout={setLayout}
          />
        );
      case 4:
        return <CallEnded hasIremoved={hasIremoved} onReturn={onReturn} />;
      default:
        return (
          <SignIn
            signInAction={onSignIn}
            conferenceId={conferenceId}
            pin={pin}
            addChatData={addChatData}
            params={params}
          />
        );
    }
  }, [
    layout,
    onSignIn,
    onReturn,
    conferenceId,
    pin,
    addChatData,
    chat,
    params,
  ]);

  return <>{renderLayout()}</>;
};

export default Home;
