/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import posthog from 'posthog-js';
import Chat from '../../components/Chat';
import ParticipantsDrawer from '../../components/ParticipantsDrawer';
import UserVideo from '../../components/UserVideo';
import VideoCallActions from '../../components/VideoCallActions';
import { useAuth } from '../../hooks/auth';
import { useSip } from '../../hooks/sip';
import { StatusCallEnum } from '../../hooks/types';
import IChat from '../../interfaces/chat';
import IMessageChat from '../../interfaces/messageChat';
import { SquadChatCommunicator } from '../../services/SquadChatCommunicator';
import { IImagePosition } from '../../services/utils/Layouts/ILayouts';
import Layouts from '../../services/utils/Layouts/Layouts';
import { getLayoutByCamsLightUp } from '../../services/utils/Layouts/layoutUtils';
import { IQueryParameters } from '../../services/utils/stringUtils';
import UserLayout from './UserLayout/UserLayout';
import './styles.scss';

import CardLoadingConference from '../../components/CardLoadingConference';
import useWindowDimensions from '../../hooks/screenWidth';
import { Commands, useSocket } from '../../hooks/socket';
import { useDevices } from '../../hooks/devices';

let resizeTimer: NodeJS.Timeout;

interface IProps {
  chat?: SquadChatCommunicator;
  conferenceId: string;
  pin: string;
  params: IQueryParameters | undefined;
  setLayout: (layout: number) => void;
}

const Conference: React.FC<IProps> = props => {
  const { conferenceId, pin, chat, params, setLayout } = props;

  const {
    setAudio,
    usersWhoseWantToSpeak,
    setUsersWhoseWantToSpeak,
    layoutString,
    setUserThatIsSharing,
    isModerator,
    myMemberName,
    myMemberId,
    isMuteVideo,
    setIsMuteVideo,
    isMuteMic,
    setIsMuteMic,
    hasSomeOneSharing,
    participants,
    sendCommand,
    socket,
  } = useSocket();

  const { device, updateDevice, user } = useAuth();
  const [oldMessagesLength, setOldMessagesLength] = useState<number>(0);
  const [newMessages, setNewMessages] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [openParticipants, setOpenParticipants] = useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openMyVideo, setOpenMyVideo] = useState<boolean>(false);
  const [getMyId, setGetMyId] = useState<boolean>(false);
  const [callId, setCallId] = useState<string>('');

  useEffect(() => {
    posthog.capture('inConference-meet', { userId: user?.id });
  }, []);

  const [showModalDevicesConfig, setShowModalDevicesConfig] =
    useState<boolean>(false);

  const [messages, setMessages] = useState<IMessageChat[]>([]);
  const [chatStarted, setChatStarted] = useState(false);
  const [newMessageBadge, setNewMessageBadge] = useState(false);

  const [showAdvisor, setShowAdvisor] = useState<boolean>(true);

  const requestSpeak = useCallback(() => {
    if (user) sendCommand(conferenceId, 'requestSpeak', user);
  }, [conferenceId, sendCommand, user]);

  const [LayoutsService, setLayoutsService] = useState<Layouts>();
  const [layouts, setLayouts] = useState<IImagePosition[]>([]);
  const {
    startCall,
    endCall,
    callsDialler,
    cleanupMedia,
    sessions,
    loading,
    conferenceScreenShare,
    sessionsScharedScreeen,
    setSessionsScharedScreeen,
    reinvite,
    unMuteCam,
    muteCam,
    setDeviceConfig,
    isSecond,
    hasAudio,
    setHasAudio,
    hasVideo,
    unMuteMic,
    muteMic,
    isBlocked,
    onScreenShare,
    setOnScreenShare,
    closeSharedScreen,
    setCloseSharedScreen,
    sharedClosing,
    setSharedClosing,
    handleHasDevices,
    camUnaviable,
    micUnaviable,
  } = useSip();
  const audioInOnCall = useRef<HTMLAudioElement | null>(null);
  const { width } = useWindowDimensions();

  const handleMobileScreen = (widthScreen: number) => {
    // console.log('widthScreen', widthScreen);
  };

  useEffect(() => {
    handleMobileScreen(width);
  }, [width]);

  const handleMicState = useCallback(() => {
    setIsMuteMic(true);
    if (myMemberId) {
      sendCommand(conferenceId, 'tmute', myMemberId);
    }
  }, [conferenceId, myMemberId, sendCommand]);

  const handleCamState = useCallback(() => {
    setIsMuteVideo(true);
    if (myMemberId) {
      sendCommand(conferenceId, 'tvmute', myMemberId);
    }
  }, [conferenceId, myMemberId, sendCommand]);

  useEffect(() => {
    if ((!hasAudio && !loading && isSecond) || micUnaviable) {
      handleMicState();
    }
    if ((!hasVideo && !loading && isSecond) || camUnaviable) {
      handleCamState();
      setIsMuteVideo(true);
    }
  }, [
    camUnaviable,
    handleCamState,
    handleMicState,
    hasAudio,
    hasVideo,
    isSecond,
    loading,
    micUnaviable,
    setIsMuteVideo,
  ]);

  const unMuteCamLocal = useCallback(() => {
    // enableSenderTracks(true, sessions[0], true);
    unMuteCam();
    // deviceIdVideo?: string,
    // deviceMicId?: string,
    // deviceSpeakerId?: string,
    reinvite(
      { audio: !isMuteMic, video: true },
      conferenceId,
      'tvmute',
      myMemberId,
      sendCommand,
      true,
      undefined,
      undefined,
      undefined,
    );
    // sendCommand(conferenceId, 'tvmute', myMemberId);
    setIsMuteVideo(false);
  }, [conferenceId, isMuteMic, myMemberId, reinvite, sendCommand, unMuteCam]);

  const handleChangeAnyDevice = useCallback(
    (deviceVideoId: string) => {
      reinvite(
        { audio: isMuteMic, video: isMuteVideo },
        conferenceId,
        'changeDevice',
        myMemberId,
        sendCommand,
        true,
        deviceVideoId,
        undefined,
        undefined,
      );
    },
    [conferenceId, isMuteMic, isMuteVideo, myMemberId, reinvite, sendCommand],
  );

  const handleChangeMicDevice = useCallback(
    (deviceMicId: string) => {
      reinvite(
        { audio: isMuteMic, video: isMuteVideo },
        conferenceId,
        'changeDeviceMic',
        myMemberId,
        sendCommand,
        true,
        undefined,
        deviceMicId,
        undefined,
      );
    },
    [conferenceId, isMuteMic, isMuteVideo, myMemberId, reinvite, sendCommand],
  );

  const handleChangeSpeackerDevice = useCallback(
    (deviceSpeakerId: string) => {
      console.log(
        'deviceSpeakerId handleChangeSpeackerDevice',
        deviceSpeakerId,
      );

      reinvite(
        { audio: isMuteMic, video: isMuteVideo },
        conferenceId,
        'changeDeviceSpeaker',
        myMemberId,
        sendCommand,
        true,
        undefined,
        undefined,
        deviceSpeakerId,
      );
    },
    [conferenceId, isMuteMic, isMuteVideo, myMemberId, reinvite, sendCommand],
  );

  const muteCamLocal = useCallback(() => {
    // enableSenderTracks(false, sessions[0], true);
    muteCam({ audio: !isMuteMic, video: false });
    reinvite(
      { audio: !isMuteMic, video: false },
      conferenceId,
      'tvmute',
      myMemberId,
      sendCommand,
      true,
    );
    // sendCommand();
    setIsMuteVideo(true);
  }, [conferenceId, isMuteMic, muteCam, myMemberId, reinvite, sendCommand]);

  const unMuteMicLocal = useCallback(() => {
    // enableSenderTracks(true, sessions[0], false);

    unMuteMic();

    reinvite(
      { audio: true, video: !isMuteVideo },
      conferenceId,
      'tmute',
      myMemberId,
      sendCommand,
      false,
      undefined,
      undefined,
      undefined,
    );

    setDeviceConfig(state => {
      return {
        ...state,
        useAudio: true,
      };
    });
    // setHasAudio(true);

    setIsMuteMic(false);
  }, [
    conferenceId,
    isMuteVideo,
    myMemberId,
    reinvite,
    setDeviceConfig,
    setHasAudio,
    sendCommand,
    unMuteMic,
  ]);

  const muteMicLocal = useCallback(() => {
    // enableSenderTracks(false, sessions[0], false);

    muteMic({ audio: false, video: !isMuteVideo });
    reinvite(
      { audio: false, video: !isMuteVideo },
      conferenceId,
      'tmute',
      myMemberId,
      sendCommand,
      false,
      undefined,
      undefined,
      undefined,
    );

    setIsMuteMic(true);
  }, [
    muteMic,
    isMuteVideo,
    conferenceId,
    user?.id,
    myMemberId,
    reinvite,
    sendCommand,
    setIsMuteMic,
  ]);

  const openChatAction = useCallback(() => {
    setOpenParticipants(false);
    setOpenChat(!openChat);
    setNewMessageBadge(false);
  }, [openChat]);

  const openParticipantsAction = useCallback(() => {
    setOpenChat(false);
    setOpenParticipants(!openParticipants);
  }, [openParticipants]);

  const changeLayout = useCallback(
    (layout: string) => {
      // console.log('(POP) Change layout', myMemberId, sessions);
      sendCommand(conferenceId, 'setLayout', `${layout} ${myMemberId}`);
      if (
        layout === 'presenter-overlap-small-top-right' ||
        layout === 'presenter-overlap-small-bot-right'
      ) {
        sendCommand(conferenceId, 'setPresentation', myMemberId);
      }
    },
    [sendCommand, conferenceId, myMemberId, sessions],
  );

  const handleVidFloor = useCallback(
    (memberId: string) => {
      sendCommand(conferenceId, 'vidFloor', memberId);
    },
    [conferenceId, sendCommand],
  );

  const sendCommandConference = useCallback(
    (command: Commands, memberId: string | undefined) => {
      if (memberId === myMemberId) {
        // console.log('meu', conferenceId, command, memberId, myMemberId);
        switch (command) {
          case 'tmute':
            if (!isMuteMic) {
              muteMicLocal();
            } else {
              unMuteMicLocal();
            }
            break;
          case 'tvmute':
            if (!isMuteVideo) {
              muteCamLocal();
            } else {
              unMuteCamLocal();
            }
            break;
          case 'vidFloor':
            handleVidFloor(memberId);
            break;
          case 'setPresentation':
            // console.log('TESTE 1 sendCommand', myMemberId);
            sendCommand(conferenceId, 'setPresentation', myMemberId);
            break;
          default:
            break;
        }
      } else {
        // console.log('de outro', conferenceId, command, memberId);
        sendCommand(conferenceId, command, memberId);
      }
    },
    [
      myMemberId,
      conferenceId,
      isMuteMic,
      isMuteVideo,
      handleVidFloor,
      sendCommand,
      muteMicLocal,
      unMuteMicLocal,
      muteCamLocal,
      unMuteCamLocal,
    ],
  );
  // socketMeetUrl
  const screenShare = useCallback(async () => {
    // console.log('setOnScreenShare linha 357', sessionsScharedScreeen);
    if (!sessionsScharedScreeen) {
      // console.log('setOnScreenShare linha 359');
      try {
        await conferenceScreenShare(conferenceId);
        setUserThatIsSharing(user?.name);
        setOnScreenShare(true);
        changeLayout('presenter-overlap-small-top-right');
        // console.log('(SHARED) shared Done', sessionsScharedScreeen);
      } catch (err) {
        console.log('(SHARED) error in shared', err);
        // eslint-disable-next-line no-console
        // console.log(err);
        // console.log('setOnScreenShare linha 368');
        setOnScreenShare(false);
        setUserThatIsSharing(undefined);
        setSessionsScharedScreeen(undefined);
      }
    } else {
      sendCommand(conferenceId, 'setPresentationClear', 'all');
      sendCommand(conferenceId, 'setLayout', 'group:grid');
      // console.log('setOnScreenShare linha 376');
      setOnScreenShare(false);
      setCloseSharedScreen(false);
      endCall(sessionsScharedScreeen.id);
      setSessionsScharedScreeen(undefined);
      setUserThatIsSharing(undefined);
    }
  }, [
    sessionsScharedScreeen,
    conferenceScreenShare,
    conferenceId,
    setUserThatIsSharing,
    user?.name,
    setOnScreenShare,
    changeLayout,
    setSessionsScharedScreeen,
    sendCommand,
    setCloseSharedScreen,
    endCall,
  ]);

  const initChat = useCallback(async () => {
    if (chat && !chatStarted) {
      try {
        await chat.makeChat((event, data) => {
          console.log('initial CHat');
        });
        const jid = `${conferenceId}@conference.${chat.getXmmppDomain()}`;
        chat.joinGroup(jid);
        setChatStarted(true);
      } catch {
        // console.log('Falha ao conectar com o chat');
      }
    }
  }, [chat, conferenceId, chatStarted]);

  const sendMessage = useCallback(
    (text: string) => {
      const jid = `${conferenceId}@conference.${chat?.getXmmppDomain()}`;
      const chatData = {
        jid,
      } as IChat;
      chat?.sendMessage(chatData, text, () => {
        // mensagem enviada
      });
    },
    [chat, conferenceId],
  );

  const sendFile = useCallback(
    (files: File[]) => {
      const jid = `${conferenceId}@conference.${chat?.getXmmppDomain()}`;
      const chatData = {
        jid,
      } as IChat;
      chat?.sendFiles(chatData, files, () => {
        // arquivo enviado
      });
    },
    [chat, conferenceId],
  );

  const isUserSharing = (participantName: string) => {
    if (
      participants.find(
        item => item.name === participantName.concat(' (Screen)'),
      )
    ) {
      return 'onScreenShare';
    }
    return '';
  };

  useEffect(() => {
    initChat();
  }, [initChat]);

  useEffect(() => {
    if (!getMyId) {
      setTimeout(() => {
        if (user?.id && isSecond && conferenceId) {
          sendCommand(conferenceId, `getMemberId`, user?.id);
          setGetMyId(true);
        }
      }, 5000);
    }
  }, [conferenceId, isSecond, user, getMyId]);

  useEffect(() => {
    if (sessions.length === 0 && isSecond) {
      setLayout(4);
    }
  }, [isSecond, sessions, setLayout]);

  useEffect(() => {
    if (messages.length > 0) {
      if (oldMessagesLength < messages.length) {
        if (!openChat) {
          setNewMessages(true);
        }
        setOldMessagesLength(messages.length);
      }
    }
  }, [messages.length, oldMessagesLength, openChat]);

  const onSquadEvents = useCallback(() => {
    // return squadEvent.disconnect();
  }, []);

  const onChatEvent = useCallback(() => {
    if (chat) {
      const messagesList: IMessageChat[] = [];
      chat.subscribe((event, data) => {
        if (event === 'message') {
          const name = data.fromUser.split('_').join(' ');
          const messageData: IMessageChat = {
            id: data.id,
            content: data.message,
            name,
            isFileMessage: data.isFileMessage,
            isImageMessage: data.isImageMessage,
            date: data.deliverTimestamp,
            type:
              data.fromUser.split('_').join(' ') === data.userName
                ? 'my-message'
                : 'another-message',
          };
          messagesList.push(messageData);
          setMessages([]);
          setMessages(messagesList);
        }
      });
    }
  }, [chat]);

  const toggleAdvisor = () => {
    setShowAdvisor(!showAdvisor);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      // e.preventDefault();
      // e.returnValue = '';
      if (sessions.length > 0) {
        sessions.forEach(sessionLocal => {
          endCall(sessionLocal.id);
        });
      }
      if (sessionsScharedScreeen) {
        endCall(sessionsScharedScreeen.id);
      }
    });
  }, [endCall, sessions, sessionsScharedScreeen]);

  useEffect(() => {
    if (sharedClosing && sessionsScharedScreeen) {
      screenShare();
      setSharedClosing(false);
    }
  }, [
    closeSharedScreen,
    conferenceId,
    screenShare,
    sessionsScharedScreeen,
    setSharedClosing,
    sharedClosing,
  ]);

  useEffect(() => {
    onChatEvent();
  }, [onChatEvent]);

  useEffect(() => {
    onSquadEvents();
  }, [onSquadEvents]);

  useEffect(() => {
    if (videoRef.current) {
      if (!LayoutsService) {
        const layoutsObject = new Layouts(
          getLayoutByCamsLightUp(
            participants.filter(p => p.camera === 'on').length,
            'group:grid',
          ) || '1x1',
          videoRef.current.offsetHeight,
          videoRef.current.offsetWidth,
        );
        setLayouts(layoutsObject?.getLayoutImages() || []);
        setLayoutsService(layoutsObject);

        window.addEventListener('resize', () => {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(() => {
            if (videoRef.current) {
              layoutsObject.updateHeight(videoRef.current.offsetHeight);
              layoutsObject.updateWidth(videoRef.current.offsetWidth);
              setLayouts(layoutsObject.getLayoutImages() || []);
            }
          }, 250);
        });
      } else {
        LayoutsService?.updateLayout(
          (layoutString?.includes('group:')
            ? getLayoutByCamsLightUp(
                participants.filter(p => p.camera === 'on').length,
                layoutString,
              )
            : layoutString) ||
            getLayoutByCamsLightUp(
              participants.filter(p => p.camera === 'on').length,
              'group:grid',
            ) ||
            '1x1',
        );
        setLayouts(LayoutsService?.getLayoutImages() || []);
        LayoutsService.updateLayout(layoutString);
      }
    }
  }, [participants, videoRef, layoutString, LayoutsService]);

  const endCallId = useCallback(() => {
    const sizeCallsDialler = callsDialler.filter(
      aux => aux.status === StatusCallEnum.connected,
    );
    if (sizeCallsDialler.length > 0) {
      // holdCall(true, callsDialler[0].sessionId);
      const calls = callsDialler.sort(
        (a, b) =>
          new Date(a.callDateTime).getTime() -
          new Date(b.callDateTime).getTime(),
      );
      // removeVideo(calls[0].sessionId);
      endCall(calls[0].sessionId);
      setLayout(4);
    }
  }, [callsDialler]);

  useEffect(() => {
    startCall();
    setTimeout(() => {
      startCall(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (callsDialler.length === 0) {
      cleanupMedia();
    }
    const sizeCallsDialler = callsDialler.filter(
      aux => aux.status === StatusCallEnum.connected,
    );
    if (sizeCallsDialler.length === 2) {
      const calls = callsDialler.sort(
        (a, b) =>
          new Date(a.callDateTime).getTime() -
          new Date(b.callDateTime).getTime(),
      );
      endCall(calls[0].sessionId);
      setAudio(new Audio());
    }
  }, [callsDialler, cleanupMedia, endCall]);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-conference">
            <CardLoadingConference />
          </div>
        </>
      ) : (
        <>
          {width <= 415 ? (
            <>
              <div className="conference-page">
                <div className="video-layout-container">
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    poster="https://images2.alphacoders.com/115/1155484.png"
                    className={`layout-call ${
                      participants.length > 6 && 'expand'
                    }`}
                    id="remote-stream-0"
                  />
                </div>
                <aside
                  className={`users-layout-container ${
                    participants.length > 6 && 'expand'
                  }`}
                >
                  <body
                    className={`users-layout-content loading ${
                      participants.length > 6 && 'expand'
                    }`}
                  >
                    {participants ? (
                      participants.map(user => {
                        if (
                          user &&
                          user.itsNew &&
                          !user.name.includes('(Screen)')
                        ) {
                          return (
                            <UserLayout
                              user={user}
                              key={user.memberId}
                              mic={user.mic}
                              talking={user.talking}
                              name={user.name}
                              profileImgUrl={
                                user.avatar ? user.avatar : undefined
                              }
                              onScreenShare={onScreenShare}
                              userThatIsSharing={isUserSharing(
                                user.name as string,
                              )}
                              handsUp={
                                !!usersWhoseWantToSpeak.find(
                                  item => item.userName === user.name,
                                )
                              }
                            />
                          );
                        }
                        return <></>;
                      })
                    ) : (
                      <div className="loading-text">
                        Carregando participantes...
                      </div>
                    )}
                  </body>
                </aside>
                <VideoCallActions
                  endCall={endCallId}
                  openParticipants={openParticipantsAction}
                  openChat={openChatAction}
                  openMyVideo={() => setOpenMyVideo(!openMyVideo)}
                  muteCam={muteCamLocal}
                  unMuteCam={unMuteCamLocal}
                  muteMic={muteMicLocal}
                  unMuteMic={unMuteMicLocal}
                  videoSocket={!isMuteVideo}
                  micSocket={!isMuteMic}
                  newMessage={newMessageBadge}
                  inScreenShare={onScreenShare}
                  screenShare={screenShare}
                  changeLayout={changeLayout}
                  participants={participants}
                  usersWhoseWantToSpeak={usersWhoseWantToSpeak}
                  setUsersWhoseWantToSpeak={setUsersWhoseWantToSpeak}
                  requestSpeak={requestSpeak}
                  isSquad={params?.isSquad === 'true'}
                  newMessages={newMessages}
                  setNewMessages={setNewMessages}
                  showModalDevicesConfig={showModalDevicesConfig}
                  setShowModalDevicesConfig={setShowModalDevicesConfig}
                  isBlocked={isBlocked}
                  params={params}
                  handleChangeAnyDevice={handleChangeAnyDevice}
                  handleChangeMicDevice={handleChangeMicDevice}
                  handleChangeSpeackerDevice={handleChangeSpeackerDevice}
                />
                {openParticipants && (
                  <ParticipantsDrawer
                    participants={participants}
                    myMemberName={myMemberName}
                    openParticipants={() => {
                      setOpenParticipants(!openParticipants);
                    }}
                    sendCommand={sendCommandConference}
                    isModerator={isModerator}
                    userMemberId={myMemberId}
                    screenShare={screenShare}
                    hasSomeOneSharing={hasSomeOneSharing}
                  />
                )}
                <div className={`chat-${openChat ? 'open' : 'close'}`}>
                  <Chat
                    closeChat={() => setOpenChat(false)}
                    sendMessage={sendMessage}
                    sendFile={sendFile}
                    messages={messages}
                  />
                </div>
                <UserVideo openVideo={openMyVideo} />
              </div>
            </>
          ) : (
            <>
              <div className="conference-page">
                <div className="video-layout-container">
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    poster="https://images2.alphacoders.com/115/1155484.png"
                    // controls
                    className={`layout-call ${
                      participants.length > 6 && 'expand'
                    }`}
                    id="remote-stream-0"
                  />
                </div>
                <aside
                  className={`users-layout-container ${
                    participants.length > 6 && 'expand'
                  }`}
                >
                  <body
                    className={`users-layout-content loading ${
                      participants.length > 6 && 'expand'
                    }`}
                  >
                    {participants ? (
                      participants.map(user => {
                        if (
                          user &&
                          user.itsNew &&
                          !user.name.includes('(Screen)')
                        ) {
                          return (
                            <UserLayout
                              user={user}
                              key={user.memberId}
                              mic={user.mic}
                              talking={user.talking}
                              name={user.name}
                              profileImgUrl={
                                user.avatar ? user.avatar : undefined
                              }
                              onScreenShare={onScreenShare}
                              userThatIsSharing={isUserSharing(
                                user.name as string,
                              )}
                              handsUp={
                                !!usersWhoseWantToSpeak.find(
                                  item =>
                                    item.userName.split(`<squad>`)[1] ===
                                    user.name.split(`<squad>`)[1],
                                )
                              }
                            />
                          );
                        }
                        return <></>;
                      })
                    ) : (
                      <div className="loading-text">
                        Carregando participantes...
                      </div>
                    )}
                  </body>
                </aside>
                <VideoCallActions
                  endCall={endCallId}
                  openParticipants={openParticipantsAction}
                  openChat={openChatAction}
                  openMyVideo={() => setOpenMyVideo(!openMyVideo)}
                  muteCam={muteCamLocal}
                  unMuteCam={unMuteCamLocal}
                  muteMic={muteMicLocal}
                  unMuteMic={unMuteMicLocal}
                  videoSocket={!isMuteVideo}
                  micSocket={!isMuteMic}
                  newMessage={newMessageBadge}
                  inScreenShare={onScreenShare}
                  screenShare={screenShare}
                  changeLayout={changeLayout}
                  participants={participants}
                  usersWhoseWantToSpeak={usersWhoseWantToSpeak}
                  setUsersWhoseWantToSpeak={setUsersWhoseWantToSpeak}
                  requestSpeak={requestSpeak}
                  isSquad={params?.isSquad === 'true'}
                  newMessages={newMessages}
                  setNewMessages={setNewMessages}
                  showModalDevicesConfig={showModalDevicesConfig}
                  setShowModalDevicesConfig={setShowModalDevicesConfig}
                  isBlocked={isBlocked}
                  params={params}
                  handleChangeAnyDevice={handleChangeAnyDevice}
                  handleChangeMicDevice={handleChangeMicDevice}
                  handleChangeSpeackerDevice={handleChangeSpeackerDevice}
                />
                {openParticipants && (
                  <ParticipantsDrawer
                    participants={participants}
                    myMemberName={myMemberName}
                    openParticipants={() => {
                      setOpenParticipants(!openParticipants);
                    }}
                    sendCommand={sendCommandConference}
                    screenShare={screenShare}
                    isModerator={isModerator}
                    userMemberId={myMemberId}
                    hasSomeOneSharing={hasSomeOneSharing}
                  />
                )}
                <div className={`chat-${openChat ? 'open' : 'close'}`}>
                  <Chat
                    closeChat={() => setOpenChat(false)}
                    sendMessage={sendMessage}
                    sendFile={sendFile}
                    messages={messages}
                  />
                </div>
                <UserVideo openVideo={openMyVideo} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Conference;
