export interface IQueryParameters {
  conferenceId: string;
  conferenceType: string;
  conferenceTitle: string | undefined;
  pin: string | undefined;
  isSquad: string | undefined;
  closeOnHangup: string | undefined;
  sipDomain: string | undefined;
  sipPort: string | undefined;
  sipPw: string | undefined;
  sipUser: string | undefined;
  websocketUrl2: string | undefined;
  userName: string | undefined;
  socketUrl?: string | undefined;
  domain?: string | undefined;
}
export const queryStringToObject = (queryString: string): IQueryParameters => {
  // Removing '?'
  const pairs = queryString.substring(1).split('&');

  const array = pairs.map(el => {
    const parts = el.split('=');
    return parts;
  });

  return Object.fromEntries(array);
};
