import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiCameraLine,
  RiCameraOffLine,
  RiLogoutBoxRLine,
  RiMacFill,
  RiMicLine,
  RiMicOffLine,
  RiPresentationLine,
} from 'react-icons/ri';
// import { UncontrolledTooltip } from 'reactstrap';
import { parseName } from '../../helpers/parseName';
import { useSip } from '../../hooks/sip';
import { IParticipantItem } from '../../interfaces/participant';
import { Commands } from '../../services/SquadEventsCommunicator';
import './styles.scss';

interface IProps {
  participant: IParticipantItem;
  participants: IParticipantItem[];
  sendCommand(command: Commands, memberId: string | undefined): void;
  screenShareToClose(): void;
  isModerator: boolean;
  myMemberName: string;
  userMemberId: string;
  hasSomeOneSharing: boolean;
}

const ParticipantItem: React.FC<IProps> = props => {
  const {
    participant: {
      name,
      avatar,
      status,
      camera,
      mic,
      screenShare,
      talking,
      memberId,
      moderator,
    },
    sendCommand,
    participants,
    screenShareToClose,
    isModerator,
    userMemberId,
    myMemberName,
    hasSomeOneSharing,
  } = props;
  const { t } = useTranslation();
  const { deviceConfig } = useSip();
  const formatLabelAvatar = useCallback(() => {
    return name.charAt(0).toUpperCase();
  }, [name]);
  const muteUnMuteMic = useCallback(() => {
    if (isModerator && mic === 'on') {
      sendCommand('tmute', String(memberId));
    } else if (myMemberName === name) {
      sendCommand('tmute', String(memberId));
    }
    // if ((isModerator && mic === 'on') || myMemberName === name) {
    //   sendCommand('tmute', String(memberId));
    // }
  }, [isModerator, mic, myMemberName, name, sendCommand, memberId]);
  const muteUnMuteCam = useCallback(() => {
    if (isModerator && camera === 'on') {
      sendCommand('tvmute', String(memberId));
    } else if (myMemberName === name) {
      sendCommand('tvmute', String(memberId));
    }
    // if ((isModerator && camera === 'on') || myMemberName === name) {
    //   sendCommand('tvmute', String(memberId));
    // }
  }, [isModerator, camera, myMemberName, name, sendCommand, memberId]);

  const kickParticipant = useCallback(() => {
    if (isModerator) {
      if (hasSomeOneSharing) {
        const findedParticipant = participants.find(
          aux => aux.memberId === memberId,
        );
        if (findedParticipant?.name === `${myMemberName} (Screen)`) {
          screenShareToClose();
        } else {
          const findShareScreen = participants.find(
            aux =>
              aux.name.includes('(Screen)') &&
              aux.name.includes(findedParticipant?.name as string),
          );
          if (findShareScreen) {
            sendCommand('kick', String(findShareScreen.memberId));
          }
          sendCommand('kick', String(memberId));
          sendCommand('setPresentationClear', 'all');
          sendCommand('setLayout', 'group:grid');
        }
      } else {
        sendCommand('kick', String(memberId));
      }
    }
  }, [
    isModerator,
    memberId,
    participants,
    myMemberName,
    hasSomeOneSharing,
    screenShareToClose,
    sendCommand,
  ]);

  const setFloorParticipant = useCallback(() => {
    if (isModerator) {
      sendCommand('vidFloor', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  const setPresenterInApresentation = useCallback(() => {
    if (isModerator) {
      sendCommand('setPresentation', String(memberId));
    }
  }, [sendCommand, memberId, isModerator]);

  const handleFixPresentation = useCallback(() => {
    if (hasSomeOneSharing) {
      return (
        <>
          <RiMacFill
            id="setFloor"
            className={`talking-${screenShare === 'on' ? 'on' : 'off'}`}
            onClick={setFloorParticipant}
          />
          {/* <UncontrolledTooltip target="setFloor" placement="top">
            {t('setFloor')}
          </UncontrolledTooltip> */}
        </>
      );
    }

    return (
      <>
        <RiMacFill id="setFloor" onClick={setFloorParticipant} />
      </>
    );
  }, [hasSomeOneSharing, screenShare, setFloorParticipant]);

  const handleMicrofone = useCallback(() => {
    if (mic === 'off' || !deviceConfig.useAudio) {
      return (
        <>
          <RiMicOffLine
            id="setMicOnOff"
            className="off"
            onClick={muteUnMuteMic}
          />
        </>
      );
    }

    return (
      <>
        <RiMicLine
          id="setMicOnOff"
          className={`talking-${talking ? 'on' : 'off'}`}
          onClick={muteUnMuteMic}
        />
      </>
    );
  }, [deviceConfig.useAudio, mic, muteUnMuteMic, talking]);

  return (
    <div className="participants-item">
      <div className="participant-info">
        <div className="participant-avatar">
          {avatar !== undefined ? (
            <img src={avatar} alt="participant" />
          ) : (
            <span className="participant-avatar">{formatLabelAvatar()}</span>
          )}
          <span className={`participant-status ${status}`} />
        </div>
        <strong>
          {`${parseName(name)} ${
            name.includes('(Screen)') ? t('isPresentation') : ''
          } ${moderator ? `(${t('Moderator')})` : ''}`}
        </strong>
      </div>
      <div
        className={`participant-actions moderator-${
          isModerator ? 'on' : 'off'
        }`}
      >
        {name.includes('(Screen)') ? (
          <>{handleFixPresentation()}</>
        ) : (
          <>
            {handleMicrofone()}
            {/* <UncontrolledTooltip target="setMicOnOff" placement="top">
              {t('setMicOnOff')}
            </UncontrolledTooltip> */}
            {camera === 'off' ? (
              <RiCameraOffLine
                id="setCamperaOnOff"
                className="off"
                onClick={muteUnMuteCam}
              />
            ) : (
              <RiCameraLine id="setCamperaOnOff" onClick={muteUnMuteCam} />
            )}
            {/* <UncontrolledTooltip target="setCamperaOnOff" placement="top">
              {t('setCamperaOnOff')}
            </UncontrolledTooltip> */}
          </>
        )}

        {name.includes('(Screen)') ? (
          <></>
        ) : (
          <>
            {hasSomeOneSharing ? (
              <>
                <RiPresentationLine
                  id="setPresenter"
                  className="talking-"
                  onClick={setPresenterInApresentation}
                />
                {/* <UncontrolledTooltip target="setPresenter" placement="top">
                  {t('setPresenter')}
                </UncontrolledTooltip> */}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {isModerator && userMemberId !== String(memberId) && (
          <>
            <RiLogoutBoxRLine
              id="kickCall"
              onClick={kickParticipant}
              className="kick-item"
            />
            {/* <UncontrolledTooltip target="kickCall" placement="top">
              {t('removeCall')}
            </UncontrolledTooltip> */}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(ParticipantItem);
