/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable consistent-return */
import axios from 'axios';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import short from 'short-uuid';
import {
  Invitation,
  InvitationAcceptOptions,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  RequestPendingError,
  Session,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  Web,
} from 'sip.js';
import {
  SessionDescriptionHandler,
  SessionDescriptionHandlerOptions,
} from 'sip.js/lib/platform/web';

import { useTranslation } from 'react-i18next';
import IConferenceApiResponse, {
  IConferenceChatResponse,
  IConferenceVoiceResponse,
} from '../interfaces/conference';
import dpsApi from '../services/dps';
import {
  Commands,
  SquadEvenstCommunicator,
} from '../services/SquadEventsCommunicator';
import {
  IQueryParameters,
  queryStringToObject,
} from '../services/utils/stringUtils';
import { IDeviceConfig, useAuth } from './auth';
import {
  enableReceiverTracks,
  enableSenderTracks,
  setHold,
} from './functionSip';
import {
  CallDiallerProps,
  DirectionCallEnum,
  MediaLocal,
  MediaRemote,
  StatusCallEnum,
} from './types';

type InitialStateProps = {
  video: boolean;
  audio: boolean;
};

type SipContextData = {
  userAgent: UserAgent | null;
  startCall: (isSecond?: boolean) => void;
  endCall: (sessionId: string) => void;
  reinvite: (
    constraints: MediaStreamConstraints,
    conferenceId: string,
    command: Commands,
    memberId: string,
    sendCommand: (
      conferenceId: string,
      command: Commands,
      extraParam?: string | Session,
    ) => void,
    isVideo?: boolean,
    deviceIdVideo?: string,
    deviceMicId?: string,
    deviceSpeakerId?: string,
  ) => void;
  callsDialler: CallDiallerProps[];
  // mute: boolean;
  // setMute: Dispatch<SetStateAction<boolean>>;
  acceptCall: (sessionId: string) => void;
  rejectCall: (sessionId: string) => void;
  holdCall: (hold: boolean, sessionId: string) => void;
  muteMedia: (mute: boolean, sessionId: string, callId: string) => void;
  transferCall: (sessionId: string, destinationWithDomain: string) => void;
  sendDTMF: (tone: string, sessionId: string) => void;
  permissionsGranted: boolean;
  setCallsDialler: Dispatch<SetStateAction<CallDiallerProps[]>>;
  removeVideo: (sessionId: string) => void;
  cleanupMedia: () => void;
  conferenceId: string;
  pin: string;
  params: IQueryParameters | undefined;
  sessions: Session[];
  sessionsScharedScreeen: Session | undefined;
  setSessionsScharedScreeen: (conferenceId: Session | undefined) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  conferenceScreenShare: (conferenceId: string | undefined) => void;
  devices: MediaDeviceInfo[];
  setDevices: (devices: MediaDeviceInfo[]) => void;
  deviceConfig: IDeviceConfig;
  setDeviceConfig: Dispatch<SetStateAction<IDeviceConfig>>;
  deviceSizeConfig: MediaTrackConstraints[] | null;
  setDeviceSizeConfig: Dispatch<SetStateAction<MediaTrackConstraints[] | null>>;
  muteCam: (constraints: MediaStreamConstraints) => void;
  unMuteCam: () => void;
  initialState: InitialStateProps;
  setInitialState: Dispatch<SetStateAction<InitialStateProps>>;
  isSecond: boolean;
  setIsSecond: Dispatch<SetStateAction<boolean>>;
  hasVideo: boolean;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  hasAudio: boolean;
  setHasAudio: Dispatch<SetStateAction<boolean>>;
  muteMic: (constraints: MediaStreamConstraints) => void;
  unMuteMic: () => void;
  isBlocked: boolean;
  wait: boolean;
  setWait: Dispatch<boolean>;
  onScreenShare: boolean;
  setOnScreenShare: Dispatch<boolean>;
  closeSharedScreen: boolean;
  setCloseSharedScreen: Dispatch<boolean>;
  hasIremoved: boolean;
  setHasIremoved: Dispatch<boolean>;
  sharedClosing: boolean;
  setSharedClosing: Dispatch<boolean>;
  handleHasDevices: (devices: MediaDeviceInfo[]) => boolean;
  micUnaviable: boolean;
  setMicUnaviable(currentAudioDevice: boolean): void;
  camUnaviable: boolean;
  setCamUnaviable(currentAudioDevice: boolean): void;
};

type Props = {
  children: ReactNode;
};

const SipContext = createContext<SipContextData>({} as SipContextData);

export const SipProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [userAgent, setUserAgent] = useState<UserAgent | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [closeSharedScreen, setCloseSharedScreen] = useState<boolean>(false);
  const [hasIremoved, setHasIremoved] = useState<boolean>(false);
  const [hasVideo, setHasVideo] = useState<boolean>(true);
  const [hasAudio, setHasAudio] = useState<boolean>(true);
  const [callsDialler, setCallsDialler] = useState<CallDiallerProps[]>([]);
  const [onScreenShare, setOnScreenShare] = useState(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionsScharedScreeen, setSessionsScharedScreeen] = useState<
    Session | undefined
  >();
  const [register, setRegister] = useState<Registerer>();
  const [sessionsTermineted, setSessionsTermineted] = useState<string[]>([]);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean>(false);
  const [mediaRemoteElement, setMediaRemoteElement] = useState<MediaRemote>({
    audio: new Audio(),
    video: document.createElement('video') as HTMLVideoElement,
  });
  const [mediaLocalElement, setMediaLocalElement] = useState<MediaLocal>({
    video: document.createElement('video') as HTMLVideoElement,
  });
  const location = useLocation();
  const [conferenceId, setConferenceId] = useState('');
  const [pin, setPin] = useState('');
  const [params, setParams] = useState<IQueryParameters>();
  const [loadingButton, setLoadingButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sharedClosing, setSharedClosing] = useState<boolean>(false);
  const [deviceConfig, setDeviceConfig] = useState<IDeviceConfig>(() => {
    const device = localStorage.getItem('@SquadAnnymous:device');
    if (device) {
      return JSON.parse(device);
    }
    return {} as IDeviceConfig;
  });

  const [micUnaviable, setMicUnaviable] = useState(false);
  const [camUnaviable, setCamUnaviable] = useState(false);

  const [initialState, setInitialState] = useState<InitialStateProps>({
    audio: false,
    video: false,
  });

  const [isSecond, setIsSecond] = useState<boolean>(false);

  const [participantsInCall, setParticipantsInCall] = useState(
    'Waiting for participants',
  );
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const [eventsSocketUrl, setEventsSocketUrl] = useState<string>('');
  const [deviceSizeConfig, setDeviceSizeConfig] = useState<
    MediaTrackConstraints[] | null
  >(null);
  const [squadEvent] = useState<SquadEvenstCommunicator>(() => {
    const squadEvents = new SquadEvenstCommunicator();
    return squadEvents;
  });

  const [chatConference, setChatConference] =
    useState<IConferenceChatResponse>();

  const [voiceConference, setVoiceConference] =
    useState<IConferenceVoiceResponse>();

  const [conferenceTitle, setConferenceTitle] = useState(
    t('Join the conference') as string,
  );

  const [wait, setWait] = useState(false);

  const muteMedia = useCallback(
    async (muteAux: boolean, sessionId: string, callId: string) => {
      if (sessions) {
        const session = sessions.find(aux => aux.id === sessionId);
        if (session) {
          const sessionDescriptionHandler =
            session.sessionDescriptionHandler as SessionDescriptionHandler;
          if (
            sessionDescriptionHandler &&
            sessionDescriptionHandler.peerConnection
          ) {
            const receiversList: RTCRtpSender[] =
              sessionDescriptionHandler.peerConnection.getSenders();
            receiversList.forEach((receiver: RTCRtpSender) => {
              if (receiver.track) {
                setCallsDialler(state => {
                  const call = state.find(aux => aux.id === callId);
                  if (call) {
                    call.statusAudio = muteAux;
                    return [...state.filter(aux => aux.id !== callId), call];
                  }
                  return state;
                });
                receiver.track.enabled = !muteAux;
              }
            });
          }
        }
      }
    },
    [sessions],
  );

  /** The local media stream. Undefined if call not answered. */
  const localMediaStream = useCallback(
    (session: Session): MediaStream | undefined => {
      if (session) {
        const sessionDescriptionHandler =
          session.sessionDescriptionHandler as SessionDescriptionHandler;
        if (!sessionDescriptionHandler) {
          return undefined;
        }
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
          throw new Error(
            'Session description handler not instance of web SessionDescriptionHandler',
          );
        }
        return sessionDescriptionHandler.localMediaStream;
      }
    },
    [],
  );

  /** The remote media stream. Undefined if call not answered. */
  const remoteMediaStream = useCallback(
    (session: Session): MediaStream | undefined => {
      // if (sessions) {
      //   const session = sessions.find(aux => aux.id === sessionId);
      if (session) {
        const sessionDescriptionHandler =
          session.sessionDescriptionHandler as SessionDescriptionHandler;
        if (sessionDescriptionHandler) {
          if (!sessionDescriptionHandler) {
            return undefined;
          }
          if (
            !(sessionDescriptionHandler instanceof SessionDescriptionHandler)
          ) {
            throw new Error(
              'Session description handler not instance of web SessionDescriptionHandler',
            );
          }
          return sessionDescriptionHandler.remoteMediaStream;
        }
      }
      // }
    },
    [],
  );

  /** Helper function to remove media from html elements. */
  const cleanupMedia = useCallback((): void => {
    if (mediaLocalElement.video) {
      mediaLocalElement.video.srcObject = null;
      mediaLocalElement.video.pause();
    }
    if (mediaRemoteElement) {
      if (mediaRemoteElement.audio) {
        mediaRemoteElement.audio.srcObject = null;
        mediaRemoteElement.audio.pause();
      }
      if (mediaRemoteElement.video) {
        mediaRemoteElement.video.srcObject = null;
        mediaRemoteElement.video.pause();
      }
    }
  }, [mediaLocalElement.video, mediaRemoteElement]);

  /** Helper function to attach local media to html elements. */
  const setupLocalMedia = useCallback(
    (session: Session, mediaElementAux: MediaLocal): void => {
      if (session) {
        if (!session) {
          throw new Error('Session does not exist.');
        }

        const mediaElement = mediaElementAux.video;

        if (mediaElement) {
          const localStream = localMediaStream(session);
          if (!localStream) {
            throw new Error('Local media stream undefiend.');
          }
          mediaElement.srcObject = localStream;
          mediaElement.volume = 0;
          mediaElement.play().catch((error: Error) => {
            console.error(error.message);
          });
        }
      }
    },
    [localMediaStream],
  );

  /** Helper function to attach local media to html elements. */
  const setupScreenSharedMedia = useCallback(
    (
      session: Session,
      mediaElementAux: MediaLocal,
      mediaScreen: MediaStream,
    ): void => {
      if (session) {
        if (!session) {
          throw new Error('Session does not exist.');
        }

        const mediaElement = mediaElementAux.video;

        if (mediaElement) {
          // const localStream = localMediaStream(session);
          if (!mediaScreen) {
            throw new Error('Local media stream undefiend.');
          }
          mediaElement.srcObject = mediaScreen;
          mediaElement.pause();
          mediaElement.play().catch((error: Error) => {
            console.error(error.message);
          });
          mediaScreen.onaddtrack = (): void => {
            mediaElement.load(); // Safari hack, as it doesn't work otheriwse
            mediaElement.pause();
            mediaElement.setAttribute('playsinline', 'true');
            mediaElement.play().catch((error: Error) => {
              console.error(error.message);
              // alert("ERRO NO PLAY 2");
              // alert(error.message);
            });
          };
        }
      }
    },
    [],
  );

  /** Helper function to attach remote media to html elements. */
  // OLD - setupRemoteMedia
  const setupRemoteMedia = useCallback(
    (session: Session, mediaElementAux: MediaRemote): void => {
      if (session) {
        const sessionDescriptionHandler =
          session.sessionDescriptionHandler as SessionDescriptionHandler;
        if (!sessionDescriptionHandler) {
          throw new Error('Session does not exist.');
        }

        const mediaElement = mediaElementAux.video || mediaElementAux.audio;

        if (mediaElement) {
          const remoteStream = remoteMediaStream(session);

          if (!remoteStream) {
            // alert('ERROR REMOTE STREAM');
            throw new Error('Remote media stream undefiend.');
          }

          (
            session.sessionDescriptionHandler as Web.SessionDescriptionHandler
          )?.peerConnection
            ?.getReceivers()
            .forEach(receiver => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track);
              }
            });

          mediaElement.autoplay = true; // Safari hack, because you cannot call .play() from a non user action

          mediaElement.srcObject = remoteStream;
          // mediaElement.load();
          mediaElement.pause();
          // mediaElement.setAttribute("playsinline", "true");
          mediaElement.play().catch((error: Error) => {
            console.error(error.message);
          });

          remoteStream.onaddtrack = (): void => {
            mediaElement.load(); // Safari hack, as it doesn't work otheriwse
            mediaElement.pause();
            mediaElement.setAttribute('playsinline', 'true');
            mediaElement.play().catch((error: Error) => {
              console.error('remoteStream onaddtrack', error.message);
            });
          };
        }
      }
    },
    [remoteMediaStream, deviceConfig],
  );

  const removeVideo = useCallback(
    (sessionId: string) => {
      const sessionLocal =
        sessions.find(aux => aux.id === sessionId) || sessions[0];
      enableSenderTracks(false, sessionLocal);
      enableReceiverTracks(false, sessionLocal);
    },
    [sessions],
  );

  const sendDTMF = useCallback(
    async (tone: string, sessionId: string): Promise<void> => {
      if (sessions) {
        const session = sessions.find(aux => aux.id === sessionId);

        // Validate tone
        if (!/^[0-9A-D#*,]$/.exec(tone)) {
          return Promise.reject(new Error('Invalid DTMF tone.'));
        }

        if (!session) {
          return Promise.reject(new Error('Session does not exist.'));
        }
        const dtmf = tone;
        const duration = 2000;
        const body = {
          contentDisposition: 'render',
          contentType: 'application/dtmf-relay',
          content: `Signal=${dtmf}\r\nDuration=${duration}`,
        };
        const requestOptions = { body };

        return session.info({ requestOptions }).then(() => {
          console.log('DTMF sent.');
        });
      }
    },
    [sessions],
  );

  /**
   * Puts Session on hold.
   * @param hold - Hold on if true, off if false.
   */
  const setHold = useCallback(
    async (hold: boolean): Promise<void> => {
      if (sessions) {
        const session = sessions[0];
        if (session) {
          // Just resolve if we are already in correct state
          // if (this.held === hold) {
          //   return Promise.resolve();
          // }

          const sessionDescriptionHandler =
            session.sessionDescriptionHandler as SessionDescriptionHandler;
          if (
            !(sessionDescriptionHandler instanceof SessionDescriptionHandler)
          ) {
            throw new Error(
              "Session's session description handler not instance of SessionDescriptionHandler.",
            );
          }

          const options: SessionInviteOptions = {
            requestDelegate: {
              onAccept: (): void => {
                // this.held = hold;
                enableReceiverTracks(!hold, session);
                enableSenderTracks(!hold, session);
                if (session.delegate && session.delegate) {
                  // session.delegate.onCallHold(hold);
                }
              },
              onReject: (): void => {
                console.warn(`[${session.id}] re-invite request was rejected`);
                enableReceiverTracks(!hold, session);
                enableSenderTracks(!hold, session);
                // if (delegate && this.delegate.onCallHold) {
                //   this.delegate.onCallHold(this.held);
                // }
              },
            },
          };

          // Session properties used to pass options to the SessionDescriptionHandler:
          //
          // 1) Session.sessionDescriptionHandlerOptions
          //    SDH options for the initial INVITE transaction.
          //    - Used in all cases when handling the initial INVITE transaction as either UAC or UAS.
          //    - May be set directly at anytime.
          //    - May optionally be set via constructor option.
          //    - May optionally be set via options passed to Inviter.invite() or Invitation.accept().
          //
          // 2) Session.sessionDescriptionHandlerOptionsReInvite
          //    SDH options for re-INVITE transactions.
          //    - Used in all cases when handling a re-INVITE transaction as either UAC or UAS.
          //    - May be set directly at anytime.
          //    - May optionally be set via constructor option.
          //    - May optionally be set via options passed to Session.invite().

          const sessionDescriptionHandlerOptions =
            session.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions;
          sessionDescriptionHandlerOptions.hold = hold;
          session.sessionDescriptionHandlerOptionsReInvite =
            sessionDescriptionHandlerOptions;

          // Send re-INVITE
          try {
            await session.invite(options);
            // preemptively enable/disable tracks
            enableReceiverTracks(!hold, session);
            enableSenderTracks(!hold, session);
            setCallsDialler(state => {
              const callFind = state.find(
                aux_1 => aux_1.sessionId === session.id,
              );
              if (callFind) {
                if (hold) {
                  callFind.status = StatusCallEnum.hold;
                } else {
                  callFind.status = StatusCallEnum.connected;
                }
                return [
                  ...state.filter(aux_2 => aux_2.id !== callFind.id),
                  callFind,
                ];
              }

              return state;
            });
          } catch (error) {
            if (error instanceof RequestPendingError) {
              console.error(
                `[${session.id}] A hold request is already in progress.`,
              );
            }
            throw error;
          }
        }
      }
      return Promise.reject(new Error('Session does not exist.'));
    },
    [sessions],
  );

  const handleAgente = useCallback(
    async (loadingButton: boolean, voiceConference: any, user: any) => {
      if (!loadingButton && voiceConference && user) {
        const uri = UserAgent.makeURI(
          `sip:${voiceConference.user}@${voiceConference.hostname}`,
        );

        let userName = user.name;

        if (userName.includes('<squad>')) {
          // eslint-disable-next-line prefer-destructuring
          userName = userName.split('<squad>')[0];
        }

        const userAgentAux = new UserAgent({
          uri,
          authorizationUsername: voiceConference.user,
          authorizationPassword: voiceConference.password,
          displayName: user.name || 'Participant',
          contactName: userName || 'Participant',
          reconnectionAttempts: 2000,
          transportOptions: {
            server: voiceConference.websocketUrl2,
            keepAliveInterval: 90,
          },
          logBuiltinEnabled: true,
          sessionDescriptionHandlerFactoryOptions: {
            iceGatheringTimeout: 500, // currently, the smallest allowed value
            peerConnectionConfiguration: {
              iceServers: [
                {
                  urls: 'stun:turn.squad.us:5349', // Replace with your STUN server's address
                },
              ],
            },
          },
        });
        setUserAgent(userAgentAux);
        const registererOptions: RegistererOptions = {};
        const registerAgent = new Registerer(userAgentAux, registererOptions);
        setRegister(registerAgent);

        await userAgentAux.start();
        registerAgent.register();
        setUserAgent(userAgentAux);
        return userAgentAux;
      }
    },
    [],
  );

  const handleHasVideo = (size: any, hasVideo: any) => {
    if (size && hasVideo) {
      return true;
    }
    return false;
  };

  const handleHasAudio = (size: any, hasAudio: any) => {
    if (size && hasAudio) {
      return true;
    }
    return false;
  };

  const playAudio = async (
    mediaElement: HTMLVideoElement,
    remoteStream: MediaStream,
    speakerId: string,
  ) => {
    if (mediaElement) {
      if (speakerId) {
        try {
          await (mediaElement as any).setSinkId(speakerId);
        } catch {
          console.log('playAudio error');
        }
      }
      mediaElement.srcObject = remoteStream;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      mediaElement.play();
    }
  };

  const setupRemoteMediaSpeaker = (
    session: Session,
    speakerId = '',
    tagId: string,
  ) => {
    if (!tagId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (session as Invitation)?.reject();
      return;
    }
    const mediaElement: HTMLVideoElement = document.getElementById(
      tagId,
    ) as HTMLVideoElement;

    const remoteStream = new MediaStream();

    (
      session.sessionDescriptionHandler as Web.SessionDescriptionHandler
    )?.peerConnection
      ?.getReceivers()
      .forEach(receiver => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    playAudio(mediaElement, remoteStream, speakerId);
  };

  const endCall = useCallback(
    (sessionId: string, isSecond?: boolean) => {
      console.log('[INFO endCall]');
      if (sessions) {
        console.log('[INFO endCall]', sessions);
        if (isSecond) {
          // sessions.forEach(sessionLocal => {
          const sessionLocal = sessions.find(aux => aux.id === sessionId);
          console.log('sessionLocal', sessionLocal);
          if (sessionLocal) {
            switch (sessionLocal.state) {
              case SessionState.Initial:
              case SessionState.Establishing:
                if (sessionLocal instanceof Inviter) {
                  // An unestablished outgoing sessionLocal
                  sessionLocal.cancel();
                } else {
                  const sessionInvitation = sessionLocal as Invitation;
                  sessionInvitation.reject();
                }
                break;
              case SessionState.Established:
                sessionLocal.bye();
                break;
              default:
                break;
            }
            // sessionLocal.bye();
            setSessions(state =>
              state.filter(aux => aux.id !== sessionLocal.id),
            );
            squadEvent.sendCommand(conferenceId, 'kill', sessionLocal.id);
            // setCallsDialler(state => state.filter(aux => aux.id !== sessionId));
          }
          // });
        } else {
          sessions.forEach(sessionLocal => {
            // const sessionLocal = sessions.find(aux => aux.id === sessionId);
            console.log('sessionLocal', sessionLocal);
            if (sessionLocal.id === sessionId) {
              switch (sessionLocal.state) {
                case SessionState.Initial:
                case SessionState.Establishing:
                  if (sessionLocal instanceof Inviter) {
                    // An unestablished outgoing sessionLocal
                    sessionLocal.cancel();
                  } else {
                    const sessionInvitation = sessionLocal as Invitation;
                    sessionInvitation.reject();
                  }
                  break;
                case SessionState.Established:
                  sessionLocal.bye();
                  break;
                default:
                  break;
              }
              // sessionLocal.bye();
              setSessions(state =>
                state.filter(aux => aux.id !== sessionLocal.id),
              );
              squadEvent.sendCommand(conferenceId, 'kill', sessionLocal.id);
              // setCallsDialler(state => state.filter(aux => aux.id !== sessionId));
            }
          });
        }
      }
    },
    [sessions, squadEvent, conferenceId],
  );

  // { mic }, { cam }, { }
  const startCall = useCallback(
    async (isSecond?: boolean) => {
      if (conferenceId && voiceConference) {
        if (isSecond) {
          setIsSecond(true);
        }
        setMediaRemoteElement({
          audio: new Audio(),
          video: document.getElementById('videoCall') as HTMLVideoElement,
        });
        setMediaLocalElement({
          video: document.getElementById('videoCallLocal') as HTMLVideoElement,
        });
        const number =
          params?.isSquad && params.conferenceType === 'group'
            ? `citrus-conference-authenticated-${conferenceId}`
            : `citrus-conference_${conferenceId}_${pin}`;
        const userTarget = `sip:${number}@${voiceConference?.hostname}`;
        // const callsDiallerFind = callsDialler.find(aux => aux.number === number);
        const agente = await handleAgente(loadingButton, voiceConference, user);
        if (agente) {
          // Send an outgoing INVITE request
          const target = UserAgent.makeURI(userTarget);
          if (!target) {
            throw new Error('Failed to create target URI.');
          }
          const size = deviceSizeConfig?.find(
            deveice => deveice.deviceId === deviceConfig.videoId,
          );
          const sizeAudio = deviceConfig.audioId || null;
          console.log('deviceConfig10', deviceConfig);
          // Create a new Inviter
          const inviterOptions: InviterOptions = {
            sessionDescriptionHandlerOptions: {
              constraints: {
                ...deviceConfig,
                audio: handleHasAudio(sizeAudio, hasAudio)
                  ? { deviceId: deviceConfig.audioId }
                  : handleHasAudio(sizeAudio, hasAudio),
                video: handleHasVideo(size, hasVideo)
                  ? isSecond
                    ? {
                        deviceId: size?.deviceId,
                        width: size?.width,
                        height: size?.height,
                      }
                    : false
                  : handleHasVideo(size, hasVideo),
              },
              // @ts-ignore
              offerOptions: {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
              },
              iceCheckingTimeout: 1000,
            },
          };
          const inviterMake = new Inviter(agente, target, inviterOptions);

          // An Inviter is a Session
          const outgoingSession: Session = inviterMake;
          setSessions(state => [...state, outgoingSession]);
          // Setup outgoing session delegate
          outgoingSession.delegate = {
            // Handle incoming REFER request.
            onRefer(referral: Referral): void {
              console.log('outgoingSession - referral', referral);
            },
            onInvite(request): void {
              console.log('outgoingSession - request', request);
            },
            onNotify(notification): void {
              console.log('outgoingSession - notification', notification);
            },
            onMessage(message): void {
              console.log('outgoingSession - message', message);
            },
          };

          const {
            request: {
              callId,
              toURI: { user },
            },
            dialog,
          } = inviterMake;

          setCallsDialler(state => {
            const callDialler = state.find(
              aux => aux.status === StatusCallEnum.connected,
            );
            if (callDialler) {
              // setHold(true);
            }
            return [
              ...state.filter(aux => aux.id !== callId),
              {
                callDateTime: new Date(),
                id: callId,
                sessionId: outgoingSession.id,
                status: StatusCallEnum.initial,
                direction: DirectionCallEnum.outbound,
                number: user || 'Número desconhecido',
                name: user || 'Nome desconhecido',
              },
            ];
          });

          outgoingSession.stateChange.addListener((newState: SessionState) => {
            // Handle outgoing session state changes.
            switch (newState) {
              case SessionState.Initial:
                console.log('dialoggg inital', dialog);
                console.log('SessionState - INICIAL', newState);
                break;
              case SessionState.Establishing:
                // Session is establishing.
                console.log('dialoggg conectando', dialog);
                console.log('SessionState - CONECTANDO', newState);
                // alert("ESTABELECENDO");
                setCallsDialler(state => {
                  return [
                    ...state.filter(aux => aux.id !== callId),
                    {
                      callDateTime: new Date(),
                      id: callId,
                      sessionId: outgoingSession.id,
                      status: StatusCallEnum.connecting,
                      direction: DirectionCallEnum.outbound,
                      number: user || 'Número desconhecido',
                      name: user || 'Nome desconhecido',
                    },
                  ];
                });
                break;
              case SessionState.Established:
                // Session has been established.
                console.log('Conexão estabelecida');
                console.log('dialoggg conectado', outgoingSession);
                console.log('SessionState - CONECTADO', newState);
                // alert("ESTABELECIDA");

                if (isSecond) {
                  setLoading(false);
                  setupRemoteMediaSpeaker(
                    outgoingSession,
                    deviceConfig.speakerId,
                    'remote-stream-0',
                  );
                }

                console.log('dialoggg connected', dialog);
                setCallsDialler(state => {
                  return [
                    ...state.filter(aux => aux.id !== callId),
                    {
                      callDateTime: new Date(),
                      id: callId,
                      sessionId: outgoingSession.id,
                      status: StatusCallEnum.connected,
                      direction: DirectionCallEnum.outbound,
                      number: user || 'Número desconhecido',
                      name: user || 'Nome desconhecido',
                    },
                  ];
                });

                break;
              case SessionState.Terminated:
                // Session has terminated.
                // console.log('callId', callId);
                console.log('Conexão encerrada', outgoingSession);
                console.log('SessionState - TERMINADO', newState);
                setSessionsTermineted(state => [...state, outgoingSession.id]);
                // setSessions(state => {
                //   console.log(state);
                //   return state.filter(aux => aux.id !== outgoingSession.id);
                // });
                setCallsDialler(state => {
                  return [...state.filter(aux => aux.id !== callId)];
                });

                if (isSecond) {
                  endCall(outgoingSession.id, isSecond);
                }
                // setCallsDialler(state => {
                //   return [
                //     ...state.filter(aux => aux.id !== callId),
                //     {
                //       callDateTime: new Date(),
                //       id: callId,
                //       status: StatusCallEnum.idle,
                //       direction: DirectionCallEnum.outbound,
                //       number: user || 'Número desconhecido',
                //       name: user || 'Nome desconhecido',
                //     },
                //   ];
                // });
                // alert("TERMINADA");
                // cleanupMedia();
                break;
              case SessionState.Terminating:
                // console.log('VEM AQUI');
                break;
              default:
                break;
            }
          });

          // Send the INVITE request
          inviterMake
            .invite()
            .then(value => {
              // INVITE sent
              console.log('inviterMake - Convite enviado', value);
            })
            .catch((error: Error) => {
              // INVITE did not send
              console.log('inviterMake - Erro ao enviar convite', error);
            });
          // setInviter(inviterMake);
        }
      }
    },
    [
      conferenceId,
      deviceConfig,
      deviceSizeConfig,
      endCall,
      handleAgente,
      hasAudio,
      hasVideo,
      loadingButton,
      params,
      pin,
      setupRemoteMedia,
      user,
      voiceConference,
    ],
  );

  const muteMic = useCallback(
    (constraints: MediaStreamConstraints) => {
      const call = sessions[0];
      if (call) {
        (
          call.sessionDescriptionHandler as Web.SessionDescriptionHandler
        ).localMediaStream
          .getTracks()
          .forEach(t => {
            if (t.kind === 'audio' && !constraints.audio) {
              t.stop();
            }
          });
      }
    },
    [sessions],
  );

  const unMuteMic = useCallback(() => {
    const call = sessions[0];
    if (call) {
      const size = deviceSizeConfig?.find(
        device => device.deviceId === deviceConfig.videoId,
      );
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: {
            deviceId: size?.deviceId,
            width: size?.width,
            height: size?.height,
          },
          // @ts-ignore
          displaySurface: 'monitor',
        })
        .then(stream => {
          (
            call.sessionDescriptionHandler as Web.SessionDescriptionHandler
          ).localMediaStream
            .getAudioTracks()
            .forEach(t => {
              if (t.kind === 'audio') {
                (
                  call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                ).localMediaStream.removeTrack(t);
              }
            });
          console.log(
            'response',
            (call.sessionDescriptionHandler as Web.SessionDescriptionHandler)
              .peerConnection,
          );
          stream.getAudioTracks().forEach(t => {
            if (t.kind === 'audio') {
              (
                call.sessionDescriptionHandler as Web.SessionDescriptionHandler
              ).localMediaStream.addTrack(t);
            }
          });
          (
            call.sessionDescriptionHandler as Web.SessionDescriptionHandler
          ).peerConnection
            ?.getSenders()
            .forEach(sender => {
              if (sender.track && sender.track.kind === 'audio') {
                sender.replaceTrack(
                  // @ts-ignore
                  stream
                    .getAudioTracks()
                    .find(state => state.kind === sender.track?.kind),
                );
              }
            });
        })
        .catch(e =>
          console.log('Error navigator.mediaDevices.getUserMedia', e),
        );
    }
  }, [deviceConfig, deviceSizeConfig, sessions]);

  const changeDeviceMic = useCallback(
    (audioId: string) => {
      console.log(`Change mic....`, audioId);
      const call = sessions[0];
      if (call) {
        const size = deviceSizeConfig?.find(
          device => device.deviceId === deviceConfig.videoId,
        );
        navigator.mediaDevices
          .getUserMedia({
            audio: {
              deviceId: audioId,
            },
            video: {
              deviceId: size?.deviceId,
              width: size?.width,
              height: size?.height,
            },
            // @ts-ignore
            displaySurface: 'monitor',
          })
          .then(stream => {
            console.log(
              'has PeerConnection',
              (
                call.sessionDescriptionHandler as Web.SessionDescriptionHandler
              ).peerConnection?.getSenders(),
            );
            (
              call.sessionDescriptionHandler as Web.SessionDescriptionHandler
            ).peerConnection
              ?.getSenders()
              .forEach(sender => {
                console.log('Track - ', sender);
                if (sender.track && sender.track.kind === 'audio') {
                  console.log('Replacing.....');
                  sender.replaceTrack(
                    // @ts-ignore
                    stream
                      .getAudioTracks()
                      .find(state => state.kind === sender.track?.kind),
                  );
                }
                if (!sender.track || sender.track === null) {
                  console.log('Adding.....');
                  (
                    call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                  ).peerConnection?.addTrack(
                    stream
                      .getAudioTracks()
                      .find(
                        state => state.kind === 'audio',
                      ) as MediaStreamTrack,
                  );
                }
              });
          })
          .catch(e =>
            console.log('Error navigator.mediaDevices.getUserMedia', e),
          );
      }
    },
    [deviceConfig.videoId, deviceSizeConfig, sessions],
  );

  const muteCam = useCallback(
    (constraints: MediaStreamConstraints) => {
      const call = sessions[0];
      if (call) {
        // console.log(
        //   'AHLOHA',
        //   (call.sessionDescriptionHandler as Web.SessionDescriptionHandler).localMediaStream.getTracks(),
        // );
        (
          call.sessionDescriptionHandler as Web.SessionDescriptionHandler
        ).localMediaStream
          .getTracks()
          .forEach(t => {
            if (t.kind === 'video' && !constraints.video) {
              t.stop();
            }
          });
      }
    },
    [sessions],
  );

  const unMuteCam = useCallback(() => {
    const call = sessions[0];
    if (call) {
      const size = deviceSizeConfig?.find(
        device => device.deviceId === deviceConfig.videoId,
      );
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: {
            deviceId: size?.deviceId,
            width: size?.width,
            height: size?.height,
          },
          // @ts-ignore
          displaySurface: 'monitor',
        })
        .then(stream => {
          (
            call.sessionDescriptionHandler as Web.SessionDescriptionHandler
          ).localMediaStream
            .getVideoTracks()
            .forEach(t => {
              if (t.kind === 'video') {
                (
                  call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                ).localMediaStream.removeTrack(t);
              }
            });
          console.log(
            'response',
            (call.sessionDescriptionHandler as Web.SessionDescriptionHandler)
              .peerConnection,
          );
          stream.getVideoTracks().forEach(t => {
            if (t.kind === 'video') {
              (
                call.sessionDescriptionHandler as Web.SessionDescriptionHandler
              ).localMediaStream.addTrack(t);
            }
          });
          (
            call.sessionDescriptionHandler as Web.SessionDescriptionHandler
          ).peerConnection
            ?.getSenders()
            .forEach(sender => {
              // console.log('THESender', sender);
              if (sender.track && sender.track.kind === 'video') {
                sender.replaceTrack(
                  // @ts-ignore
                  stream
                    .getVideoTracks()
                    .find(state => state.kind === sender.track?.kind),
                );
              }
              if (!sender.track) {
                (
                  call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                ).peerConnection?.addTrack(
                  stream
                    .getVideoTracks()
                    .find(state => state.kind === 'video') as MediaStreamTrack,
                );
              }
            });
        })
        .catch(e =>
          console.log('Error navigator.mediaDevices.getUserMedia', e),
        );
    }
  }, [deviceConfig, deviceSizeConfig, sessions]);

  const changeDeviceVideo = useCallback(
    (videoId: string) => {
      console.log(`Change camera....`);
      const call = sessions[0];
      if (call) {
        const size = deviceSizeConfig?.find(
          device => device.deviceId === videoId,
        );
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: {
              deviceId: size?.deviceId,
              width: size?.width,
              height: size?.height,
            },
            // @ts-ignore
            displaySurface: 'monitor',
          })
          .then(stream => {
            console.log(
              'has PeerConnection',
              (
                call.sessionDescriptionHandler as Web.SessionDescriptionHandler
              ).peerConnection?.getSenders(),
            );
            (
              call.sessionDescriptionHandler as Web.SessionDescriptionHandler
            ).peerConnection
              ?.getSenders()
              .forEach(sender => {
                if (sender.track && sender.track.kind === 'video') {
                  console.log('Replacing.....');
                  sender.replaceTrack(
                    // @ts-ignore
                    stream
                      .getVideoTracks()
                      .find((state: any) => state.kind === sender.track?.kind),
                  );
                }
                if (!sender.track) {
                  (
                    call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                  ).peerConnection?.addTrack(
                    stream
                      .getVideoTracks()
                      .find(
                        state => state.kind === 'video',
                      ) as MediaStreamTrack,
                  );
                }
              });
          })
          .catch(e =>
            console.log('Error navigator.mediaDevices.getUserMedia', e),
          );
      }
    },
    [deviceSizeConfig, sessions],
  );

  const changeDeviceSpeaker = useCallback(
    (skpearId: string) => {
      console.log(`Change speaker....`, skpearId);

      // const call = sessions[0];
      // if (call) {
      //   const size = deviceSizeConfig?.find(
      //     device => device.deviceId === deviceConfig.videoId,
      //   );

      //   navigator.mediaDevices
      //     .getUserMedia({
      //       audio: true,
      //       video: {
      //         deviceId: size?.deviceId,
      //         width: size?.width,
      //         height: size?.height,
      //       },
      //       // @ts-ignore
      //       displaySurface: 'monitor',
      //     })
      //     .then(stream => {
      //       console.log(
      //         'has PeerConnection',
      //         (
      //           call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //         ).peerConnection?.getReceivers(),
      //       );
      //       console.log('has PeerConnection', stream);
      //       (
      //         call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //       ).peerConnection
      //         ?.getReceivers()
      //         .forEach(receiver => {
      //           if (receiver.track.kind === 'audio') {
      //             stream.removeTrack(receiver.track);

      //             if (!receiver.track) {
      //               (
      //                 call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //               ).peerConnection?.addTrack(
      //                 stream
      //                   // @ts-ignore
      //                   .setSinkId(deviceConfig.speakerId)
      //                   .getAudioTracks()
      //                   .find(
      //                     (state: any) => state.kind === 'audio',
      //                   ) as MediaStreamTrack,
      //               );
      //             }
      //             console.log('receiver =>', receiver);
      //           }
      //         });
      //     })
      //     .catch(e =>
      //       console.log('Error navigator.mediaDevices.getUserMedia', e),
      //     );
      // }
      // const call = sessions[0];
      // if (call) {
      //   const size = deviceSizeConfig?.find(
      //     device => device.deviceId === deviceConfig.videoId,
      //   );

      //   navigator.mediaDevices
      //     .getUserMedia({
      //       audio: true,
      //       video: size
      //         ? {
      //             deviceId: size.deviceId,
      //             width: size.width,
      //             height: size.height,
      //           }
      //         : undefined, // Handle video case if needed
      //     })
      //     .then(newStream => {
      //       const existingAudioTrack = (
      //         call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //       )?.peerConnection
      //         ?.getReceivers()
      //         .find(sender => sender.track?.kind === 'audio')?.track;

      //       if (existingAudioTrack) {
      //         // Remove the existing audio track
      //         (
      //           call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //         )?.peerConnection?.removeTrack(existingAudioTrack);

      //         // Create a new MediaStreamTrack with the desired device (speakerId)
      //         navigator.mediaDevices
      //           .getUserMedia({ audio: true })
      //           .then(replacementStream => {
      //             const replacementAudioTrack =
      //               replacementStream.getAudioTracks()[0];

      //             // Handle renegotiation if necessary (replaceTrack vs. createOffer)
      //             if (
      //               replacementAudioTrack.kind !== existingAudioTrack.kind || // Check for kind mismatch
      //               replacementAudioTrack.getSettings() !==
      //                 existingAudioTrack.getSettings()
      //             ) {
      //               (
      //                 call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //               )
      //                 ?.createOffer()
      //                 .then((offer: any) => {
      //                   (
      //                     call.sessionDescriptionHandler as Web.SessionDescriptionHandler
      //                   )?.setLocalDescription(
      //                     offer,
      //                   );
      //                   // Signal the new offer to the other peer
      //                 })
      //                 .catch(error =>
      //                   console.error('Error creating offer:', error),
      //                 );
      //             } else {
      //               call.sessionDescriptionHandler?.peerConnection?.addTrack(
      //                 replacementAudioTrack,
      //               );
      //             }
      //           })
      //           .catch(error =>
      //             console.error(
      //               'Error getting replacement audio track:',
      //               error,
      //             ),
      //           );
      //       } else {
      //         console.warn('No existing audio track found in PeerConnection.');
      //       }
      //     })
      //     .catch(error =>
      //       console.error('Error getting new audio stream:', error),
      //     );
      // }
    },
    [deviceConfig.videoId, deviceSizeConfig, sessions],
  );

  const switchSpeaker = async (
    mediaElement: HTMLVideoElement,
    session: Session,
    newSpeakerId: string,
  ) => {
    if (!mediaElement) {
      console.error('Media element not found');
      return;
    }

    const peerConnection = (
      session.sessionDescriptionHandler as Web.SessionDescriptionHandler
    )?.peerConnection;
    if (!peerConnection) {
      console.error('Peer connection not found');
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    await mediaElement.pause();

    // Create a new MediaStream for the new speaker
    const newRemoteStream = new MediaStream();

    // Iterate through existing receivers, filtering for the new speaker track
    peerConnection.getReceivers().forEach(receiver => {
      newRemoteStream.addTrack(receiver.track);
    });

    try {
      // Attempt to set the new sink ID if available
      if (newSpeakerId) {
        await (mediaElement as any).setSinkId(newSpeakerId);
      }

      // Update the media element's srcObject with the new stream
      mediaElement.srcObject = newRemoteStream;

      // Play the new audio stream
      await mediaElement.play();
    } catch (error) {
      console.error('Error switching speaker:', error);
    }
  };

  // conferenceId, 'tvmute', myMemberId
  const reinvite = useCallback(
    async (
      constraints: MediaStreamConstraints,
      conferenceId: string,
      command: Commands,
      memberId: string,
      sendCommand: (
        conferenceId: string,
        command: Commands,
        extraParam?: string | Session,
      ) => void,
      isVideo?: boolean,
      deviceIdVideo?: string,
      deviceMicId?: string,
      deviceSpeakerId?: string,
    ) => {
      setBlocked(true);
      const call = sessions[0];
      if (call) {
        const options: SessionInviteOptions = {
          requestDelegate: {
            onAccept: async (): Promise<void> => {
              console.log(
                `[${call.id}] re-invite DEPOIS`,
                (
                  call.sessionDescriptionHandler as Web.SessionDescriptionHandler
                ).localMediaStream.getTracks(),
              );
            },
            onReject: (): void => {
              console.warn(`[${call.id}] re-invite request was rejected`);
            },
          },
        };

        const deviceId = deviceIdVideo || deviceConfig.videoId;

        const size = deviceSizeConfig?.find(
          device => device.deviceId === deviceId,
        );
        const sessionDescriptionHandlerOptions =
          call.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions;
        sessionDescriptionHandlerOptions.constraints = {
          ...deviceConfig,
          audio: deviceMicId
            ? {
                deviceId: deviceMicId,
              }
            : constraints.audio
            ? { deviceId: short().generate() }
            : constraints.audio,

          video: constraints.video
            ? {
                deviceId: size?.deviceId,
                width: size?.width,
                height: size?.height,
              }
            : constraints.video,
        };
        call.sessionDescriptionHandlerOptionsReInvite =
          sessionDescriptionHandlerOptions;

        // Send re-INVITE
        try {
          if (command === 'tmute') {
            if (!initialState?.audio) {
              await call.invite(options);
              setInitialState(state => {
                return {
                  video: state.video,
                  audio: true,
                };
              });
              sendCommand(conferenceId, command, memberId);
            } else {
              sendCommand(conferenceId, command, memberId);
            }
          } else if (command === 'tvmute') {
            if (!initialState.video) {
              await call.invite(options);
              setInitialState(state => {
                return {
                  video: true,
                  audio: state.audio,
                };
              });
            } else {
              sendCommand(conferenceId, command, memberId);
            }
          } else if (command === 'changeDevice') {
            changeDeviceVideo(`${deviceId}`);
          } else if (command === 'changeDeviceMic') {
            changeDeviceMic(`${deviceMicId}`);
          } else if (command === 'changeDeviceSpeaker') {
            const mediaElement: HTMLElement | HTMLVideoElement | null =
              document.getElementById('remote-stream-0');
            if (mediaElement) {
              console.log(`switchSpeaker vai entrar no metodo`);
              switchSpeaker(
                mediaElement as HTMLVideoElement,
                call,
                `${deviceSpeakerId}`,
              );
            } else {
              console.log(`switchSpeaker não achou`);
            }
            // changeDeviceSpeaker(`${deviceSpeakerId}`);
          }

          setBlocked(false);
        } catch (error) {
          if (error instanceof RequestPendingError) {
            console.error(
              `[${call.id}] A reinvite request is already in progress.`,
            );
          }
          throw error;
        }
      }
    },
    [
      sessions,
      deviceConfig,
      deviceSizeConfig,
      initialState?.audio,
      initialState.video,
      changeDeviceVideo,
      changeDeviceMic,
    ],
  );

  /**
   * Answer an incoming call.
   * @remarks
   * Accept an incoming INVITE request creating a new Session.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if and when call is established.
   * @param invitationAcceptOptions - Optional options for Inviter.accept().
   */
  const acceptCall = useCallback(
    async (
      sessionId: string,
      invitationAcceptOptions?: InvitationAcceptOptions,
    ): Promise<void> => {
      if (sessions) {
        const invitationLocal = sessions.find(aux => aux.id === sessionId);
        if (!invitationLocal) {
          return Promise.reject(new Error('Session does not exist.'));
        }

        if (!(invitationLocal instanceof Invitation)) {
          return Promise.reject(
            new Error('Session not instance of Invitation.'),
          );
        }

        // Use our configured constraints as InvitationAcceptOptions if none provided
        if (!invitationAcceptOptions) {
          invitationAcceptOptions = {};
        }
        if (!invitationAcceptOptions.sessionDescriptionHandlerOptions) {
          invitationAcceptOptions.sessionDescriptionHandlerOptions = {};
        }

        const callDialler = callsDialler.find(
          aux => aux.status === StatusCallEnum.connected,
        );
        if (callDialler) {
          // setHold(true)
          //   .catch(err =>
          //     console.error('Não foi possível colocar em espera', err),
          //   )
          //   .finally(() => {
          //     console.log('HOLD');
          //     return invitationLocal.accept(invitationAcceptOptions);
          //   });
        } else {
          return invitationLocal.accept(invitationAcceptOptions);
        }
      }
    },
    [callsDialler, sessions, setHold],
  );

  const rejectCall = useCallback(
    (sessionId: string) => {
      if (sessions) {
        const invitationLocal = sessions.find(aux => aux.id === sessionId);
        if (invitationLocal && invitationLocal instanceof Invitation) {
          setCallsDialler(state =>
            state.filter(aux => aux.sessionId !== invitationLocal.id),
          );
          invitationLocal.reject();
        }
      }
    },
    [sessions],
  );

  const holdCall = useCallback(
    (hold: boolean, sessionId: string) => {
      console.log('holdCall', hold);
      setHold(hold);
    },
    [setHold],
  );

  const transferCall = useCallback(
    (sessionId: string, destinationWithDomain: string) => {
      if (sessions) {
        // console.log('transferCall', sessionId, destinationWithDomain);
        const sessionLocal = sessions.find(aux => aux.id === sessionId);
        // console.log('transferCall', sessionLocal);
        const URI = UserAgent.makeURI(`sip:${destinationWithDomain}`);
        if (sessionLocal && URI) {
          const callExist = callsDialler.find(
            aux => aux.number === destinationWithDomain.split('@')[0],
          );
          let sessionExists: Session | undefined;
          if (callExist) {
            sessionExists = sessions.find(
              aux => aux.id === callExist.sessionId,
            );
          }
          if (sessionExists) {
            sessionLocal.refer(sessionExists);
          } else {
            sessionLocal.refer(URI);
          }
        }
      }
    },
    [callsDialler, sessions],
  );

  const getConferenceInfo = useCallback(
    async (conferenceId: string, pin: string, params: IQueryParameters) => {
      console.log('queryStringToObject', conferenceId, pin, params);
      let domainComming = '';
      if (window.location.hash.includes('domain=')) {
        // eslint-disable-next-line prefer-destructuring
        domainComming = window.location.hash.split('domain=')[1];
      }
      if (conferenceId.length) {
        if (pin) {
          const response = await dpsApi.get('', {
            // eslint-disable-next-line no-restricted-globals
            params: {
              domain: params.domain !== '' ? params.domain : 'digivox.tlx.ai',
            },
          });

          const responseApi = await axios.get<IConferenceApiResponse>(
            `${response.data.api_url}/conferences/anonymous/${conferenceId}`,
            { params: { pin } },
          );
          const { voice, chat, eventSocketURL } = responseApi.data;
          setConferenceTitle(voice.conferenceName);
          setChatConference(chat);
          setVoiceConference(voice);
          setEventsSocketUrl(eventSocketURL);
        }
        if (
          params?.isSquad === 'true' &&
          params.conferenceType === 'group' &&
          params.conferenceTitle
        ) {
          setConferenceTitle(decodeURI(params.conferenceTitle));
          setVoiceConference({
            user: params.sipUser,
            password: params.sipPw,
            hostname: params.sipDomain,
            websocketUrl2: params.websocketUrl2,
            conferenceName: 'teste',
          } as IConferenceVoiceResponse);
          setEventsSocketUrl(params.socketUrl || ``);
        }
        setLoadingButton(false);
      }
    },
    [setEventsSocketUrl],
  );

  useEffect(() => {
    if (callsDialler && sessionsTermineted) {
      const sessionFinelized = sessionsTermineted.find(aux =>
        callsDialler.find(call => call.sessionId === aux),
      );
      setSessions(state => {
        return state.filter(aux => aux.id !== sessionFinelized);
      });
    }
  }, [callsDialler, sessionsTermineted]);

  useEffect(() => {
    if (callsDialler) {
      const listConnecteds: CallDiallerProps[] = [];
      callsDialler.forEach(call => {
        if (call.status === StatusCallEnum.connected) {
          listConnecteds.push(call);
        }
      });
    }
  }, [callsDialler]);

  useEffect(() => {
    if (location.search !== '') {
      const values = queryStringToObject(location.search);
      setParams(values);
      setConferenceId(values.conferenceId);
      setPin(values.pin || '');
      getConferenceInfo(values.conferenceId, values.pin || '', values);
    }
  }, [getConferenceInfo, location]);

  const endScreenShare = useCallback(() => {
    if (sessionsScharedScreeen) {
      endCall(sessionsScharedScreeen.id);
    }
  }, [endCall, sessionsScharedScreeen]);

  const conferenceScreenShare = useCallback(
    async (conferenceId: string | undefined) => {
      endScreenShare();
      // console.log('(endScreenShare) agent', userAgent);
      // console.log('(endScreenShare) conference', conferenceId);
      if (userAgent && conferenceId) {
        const userTarget = `sip:${conferenceId}-screen@${voiceConference?.hostname}`;
        const destination = UserAgent.makeURI(userTarget);
        let screenShare: Inviter;
        if (destination) {
          const size = deviceSizeConfig?.find(
            deveice => deveice.deviceId === deviceConfig.videoId,
          );
          const sizeAudio = deviceSizeConfig?.find(
            deveice => deveice.deviceId === deviceConfig.audioId,
          );

          // @ts-ignore
          const customStream: MediaStream =
            await navigator.mediaDevices.getDisplayMedia({
              video: {
                // @ts-ignore
                cursor: 'always',
              },
              audio: true,
            });
          if (!customStream) return;

          screenShare = new Inviter(userAgent, destination, {
            params: {
              fromDisplayName: `${user?.name} (Screen)`,
            },
            sessionDescriptionHandlerOptions: {
              constraints: {
                customStream,
                audio: false,
                video: handleHasVideo(size, true),
              },
            },
          } as InviterOptions);
          if (screenShare) {
            const screen =
              screenShare.sessionDescriptionHandler as SessionDescriptionHandler;
            // if (screen && screen.peerConnection) {
            //   const videotrack = undefined;
            //   const sender = screen.peerConnection
            //     .getSenders()
            //     // @ts-ignore
            //     .find(aux => aux.track?.kind === videotrack.kind);

            //   sender?.setStreams(customStream);
            // }
            customStream
              .getVideoTracks()
              .forEach(element =>
                console.log('customStream.getVideoTracks()', element),
              );
            customStream.getVideoTracks()[0].onended = () => {
              console.log('screenShare');
              // endCall(screenShare.id);
              setSharedClosing(true);
              // setSessionsScharedScreeen(undefined);

              // endScreenShare();
            };
            setSessions(state => {
              return [...state, screenShare];
            });
            screenShare.stateChange.addListener((newState: SessionState) => {
              // Handle outgoing session state changes.
              switch (newState) {
                case SessionState.Initial:
                  console.log('SessionState - INICIAL', newState);
                  break;
                case SessionState.Establishing:
                  console.log('SessionState - CONECTANDO', newState);
                  break;
                case SessionState.Established:
                  console.log('Conexão estabelecida');
                  console.log('SessionState - CONECTADO', newState);

                  const screen =
                    screenShare.sessionDescriptionHandler as SessionDescriptionHandler;
                  if (screen && screen.peerConnection) {
                    const videotrack = customStream.getVideoTracks()[0];
                    console.log('customStream.getAudioTracks()', videotrack);
                    const sender = screen.peerConnection
                      .getSenders()
                      .find(aux => aux.track?.kind === videotrack.kind);
                    sender?.replaceTrack(videotrack);
                  }
                  break;
                case SessionState.Terminated:
                  console.log('Conexão encerrada');
                  console.log('SessionState - TERMINADO', newState);

                  break;
                case SessionState.Terminating:
                  console.log('SessionState - TERMINANDO', newState);
                  setCloseSharedScreen(true);
                  break;
                default:
                  break;
              }
            });
            setSessionsScharedScreeen(screenShare);
            screenShare.invite();
          }
        }
      }
    },
    [endCall, endScreenShare, user, userAgent, voiceConference],
  );

  const handleHasDevices = useCallback((devices: MediaDeviceInfo[]) => {
    // console.log('handleHasDevices', devices);
    if (
      !devices ||
      devices.length === 0 ||
      (devices.length === 1 && devices[0].deviceId === '')
    ) {
      if (devices && devices[0]?.kind === 'audioinput') {
        setMicUnaviable(true);
      }
      if (devices && devices[0]?.kind === 'videoinput') {
        setCamUnaviable(true);
      }
      return true; // Only one device with empty deviceId
    }

    return false;
  }, []);

  // CONTEXT SIP
  const sipValue = useMemo(() => {
    return {
      userAgent,
      startCall,
      endCall,
      callsDialler,
      // mute,
      // setMute,
      acceptCall,
      rejectCall,
      holdCall,
      muteMedia,
      transferCall,
      sendDTMF,
      permissionsGranted,
      setCallsDialler,
      removeVideo,
      cleanupMedia,
      conferenceId,
      pin,
      params,
      sessions,
      loading,
      setLoading,
      conferenceScreenShare,
      sessionsScharedScreeen,
      setSessionsScharedScreeen,
      reinvite,
      devices,
      setDevices,
      deviceConfig,
      setDeviceConfig,
      deviceSizeConfig,
      setDeviceSizeConfig,
      muteCam,
      unMuteCam,
      initialState,
      setInitialState,
      isSecond,
      setIsSecond,
      hasAudio,
      setHasAudio,
      hasVideo,
      setHasVideo,
      unMuteMic,
      muteMic,
      wait,
      setWait,
      isBlocked,
      setBlocked,
      setOnScreenShare,
      onScreenShare,
      closeSharedScreen,
      setCloseSharedScreen,
      hasIremoved,
      setHasIremoved,
      sharedClosing,
      setSharedClosing,
      handleHasDevices,
      micUnaviable,
      setMicUnaviable,
      camUnaviable,
      setCamUnaviable,
    };
  }, [
    startCall,
    userAgent,
    endCall,
    callsDialler,
    acceptCall,
    rejectCall,
    holdCall,
    muteMedia,
    transferCall,
    sendDTMF,
    permissionsGranted,
    setCallsDialler,
    removeVideo,
    cleanupMedia,
    conferenceId,
    pin,
    params,
    sessions,
    loading,
    setLoading,
    conferenceScreenShare,
    sessionsScharedScreeen,
    setSessionsScharedScreeen,
    reinvite,
    devices,
    setDevices,
    deviceConfig,
    setDeviceConfig,
    deviceSizeConfig,
    setDeviceSizeConfig,
    muteCam,
    unMuteCam,
    initialState,
    setInitialState,
    isSecond,
    setIsSecond,
    hasAudio,
    setHasAudio,
    hasVideo,
    setHasVideo,
    unMuteMic,
    muteMic,
    wait,
    setWait,
    isBlocked,
    setBlocked,
    setOnScreenShare,
    onScreenShare,
    closeSharedScreen,
    setCloseSharedScreen,
    hasIremoved,
    setHasIremoved,
    sharedClosing,
    setSharedClosing,
    handleHasDevices,
    micUnaviable,
    setMicUnaviable,
    camUnaviable,
    setCamUnaviable,
  ]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <SipContext.Provider value={sipValue}>{children}</SipContext.Provider>;
};

export function useSip(): SipContextData {
  return useContext(SipContext);
}
