/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import CardLoadingConference from '../../components/CardLoadingConference';
import './styles.scss';

interface IProps {}

const LoadingConference: React.FC<IProps> = props => {
  return (
    <div className="loading-conference">
      <CardLoadingConference />
    </div>
  );
};

export default LoadingConference;
