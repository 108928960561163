/* eslint-disable react/require-default-props */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactTextFormat from 'react-text-format';
import dayjs from 'dayjs';
import './styles.scss';
import { RiEyeLine, RiFileFill } from 'react-icons/ri';

interface IProps {
  type: 'my-message' | 'another-message';
  name: string;
  message: string;
  isImage?: boolean;
  isFile?: boolean;
  date: Date;
}

const Message: React.FC<IProps> = props => {
  const { type, name, message, isImage = false, isFile = false, date } = props;
  const messageChatRef = useRef<HTMLDivElement>(null);
  const [showImageView, setShowImageView] = useState(false);

  const customLinkDecorator = useCallback(
    (decoratedHref: string, decoratedText: string, linkTarget: string) => {
      return (
        <a href={decoratedHref} target={linkTarget}>
          {decoratedText}
        </a>
      );
    },
    [],
  );

  const openImage = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const getFileName = useCallback((url: string) => {
    const urlSplited = url.split('/');
    const fileName = urlSplited[urlSplited.length - 1];
    return fileName;
  }, []);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (messageChatRef.current?.contains(e.target)) {
        setShowImageView(true);
      } else {
        setShowImageView(false);
      }
    },
    [messageChatRef],
  );

  const renderMessage = useCallback(() => {
    if (isImage) {
      return (
        <div className="image-container" ref={messageChatRef}>
          <img src={message} alt="chat-file" />
          {showImageView && (
            <div className="actions" onClick={() => openImage(message)}>
              <RiEyeLine />
            </div>
          )}
        </div>
      );
    }

    if (isFile) {
      return (
        <div className="file-container">
          <a href={message} download>
            <RiFileFill />
            <span>{getFileName(message)}</span>
          </a>
        </div>
      );
    }

    return (
      <div>
        <ReactTextFormat
          allowedFormats={['URL']}
          linkTarget="_blank"
          LinkDecorator={customLinkDecorator}
        >
          {message}
        </ReactTextFormat>
      </div>
    );
  }, [
    isImage,
    message,
    openImage,
    messageChatRef,
    showImageView,
    customLinkDecorator,
    isFile,
    getFileName,
  ]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <div className={`message-container ${type}`}>
      <div className="message">
        <span>{name}</span>
        {renderMessage()}
        <div className="message-footer">
          <span>{dayjs(date).format('HH:mm')}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
