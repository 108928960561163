import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseFill } from 'react-icons/ri';
import { IParticipantItem } from '../../interfaces/participant';
import { Commands } from '../../services/SquadEventsCommunicator';
import ParticipantItem from '../ParticipantItem';

import './styles.scss';

interface IProps {
  participants: IParticipantItem[];
  openParticipants(): void;
  screenShare(): void;
  sendCommand(command: Commands, memberId: string | undefined): void;
  isModerator: boolean;
  userMemberId: string;
  myMemberName: string;
  hasSomeOneSharing: boolean;
}

const ParticipantsDrawer: React.FC<IProps> = props => {
  const {
    participants,
    openParticipants,
    screenShare,
    sendCommand,
    isModerator,
    userMemberId,
    myMemberName,
    hasSomeOneSharing,
  } = props;
  const containerRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  return (
    <aside ref={containerRef} className="participants-container">
      <div className="participants-container-header">
        <span>{`${t('Participants')}: ${participants.length}`}</span>
        <RiCloseFill onClick={openParticipants} />
      </div>
      <div className="participants-container-body">
        {participants.map(participant => (
          <ParticipantItem
            participant={participant}
            participants={participants}
            userMemberId={userMemberId}
            key={participant.memberId}
            sendCommand={sendCommand}
            screenShareToClose={screenShare}
            isModerator={isModerator}
            myMemberName={myMemberName}
            hasSomeOneSharing={hasSomeOneSharing}
          />
        ))}
      </div>
    </aside>
  );
};

export default ParticipantsDrawer;
