import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { Socket } from 'socket.io-client';
import Home from '../pages/Home';
import generateSocket from '../hooks/connectSocket';
import { SocketProvider } from '../hooks/socket';
import { queryStringToObject } from '../services/utils/stringUtils';

const Routes: React.FC = () => {
  const location = useLocation();
  const [socket, setSocket] = useState<Socket>();
  let socketUrl = '';
  let conferenceId = '';

  // location.search =
  //   '?conferenceId=9b1978ab-4139-4a7e-ac15-e26c4ecade47&pin=7176&socketUrl=https://lab-squad.digivox.com.br/&domain=labtest01.digivox.com.br';

  if (location && location.search !== '') {
    const values = queryStringToObject(location.search);
    if (values.socketUrl && values.conferenceId) {
      socketUrl = values.socketUrl;
      conferenceId = values.conferenceId;
    }
  }

  useEffect(() => {
    setSocket(generateSocket(socketUrl));
  }, [socketUrl, conferenceId]);

  return socket ? (
    <SocketProvider socket={socket} conferenceId={conferenceId}>
      <Switch>
        <Route path="/" exact component={Home} />
      </Switch>
    </SocketProvider>
  ) : (
    <></>
  );
};

export default Routes;
