/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unused-prop-types */
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { IoHandRightOutline } from 'react-icons/io5';
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCameraLine,
  RiCameraOffLine,
  RiErrorWarningLine,
  RiEyeLine,
  RiEyeOffLine,
  RiGroupLine,
  RiLayoutMasonryLine,
  RiMessage3Line,
  RiMicLine,
  RiMicOffLine,
  RiPhoneLine,
} from 'react-icons/ri/index';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import Webcam from 'react-webcam';
import DropdownLanguage from '../../DropdownLanguage';
import { parseName } from '../../helpers/parseName';
import { useAuth } from '../../hooks/auth';
import useWindowDimensions from '../../hooks/screenWidth';
import { IParticipantItem } from '../../interfaces/participant';
import { RequestSpeakEvent } from '../../services/SquadEventsCommunicator';
import { IQueryParameters } from '../../services/utils/stringUtils';
import './styles.scss';
import { useDevices } from '../../hooks/devices';
import { useSip } from '../../hooks/sip';
import { camResCheck } from '../../services/utils/camResCheck';
import MeetConfigVideoButton from '../MeetConfigVideoButton';
import MeetConfigMicButton from '../MeetConfigMicButton';

interface ILayoutsValue {
  value: string;
  label: string;
}

interface IProps {
  openParticipants(): void;
  openChat(): void;
  endCall(): void;
  openMyVideo(): void;
  muteCam(): void;
  unMuteCam(): void;
  screenShare(): void;
  videoSocket: boolean;
  muteMic(): void;
  unMuteMic(): void;
  micSocket: boolean;
  newMessage: boolean;
  inScreenShare: boolean;
  changeLayout(layout: string): void;
  participants: IParticipantItem[];
  usersWhoseWantToSpeak: RequestSpeakEvent[];
  setUsersWhoseWantToSpeak(value: RequestSpeakEvent[]): void;
  requestSpeak(): void;
  isSquad: boolean;
  newMessages: boolean;
  setNewMessages(value: boolean): void;
  showModalDevicesConfig: boolean;
  setShowModalDevicesConfig(value: boolean): void;
  isBlocked: boolean;
  params: IQueryParameters | undefined;
  handleChangeAnyDevice: (device: string) => void;
  handleChangeMicDevice: (device: string) => void;
  handleChangeSpeackerDevice: (device: string) => void;
}

const VideoCallActions: React.FC<IProps> = props => {
  const {
    deviceMic,
    deviceSpeaker,
    deviceVideo,
    setDeviceMic,
    setDeviceSpeaker,
    setDeviceVideo,
    setVideo,
    video,
    mic,
    setMic,
    setCurrentAudioDevice,
    setCurrentSpeakerDevice,
    currentAudioDevice,
    currentSpeakerDevice,
  } = useDevices();
  const {
    openParticipants,
    endCall,
    openChat,
    openMyVideo,
    muteCam,
    unMuteCam,
    muteMic,
    unMuteMic,
    videoSocket,
    micSocket,
    screenShare,
    inScreenShare,
    changeLayout,
    usersWhoseWantToSpeak,
    requestSpeak,
    setUsersWhoseWantToSpeak,
    isSquad,
    newMessages,
    setNewMessages,
    isBlocked,
    participants,
    params,
    handleChangeAnyDevice,
    handleChangeMicDevice,
    handleChangeSpeackerDevice,
  } = props;
  const {
    setDeviceConfig,
    hasAudio,
    setHasAudio,
    hasVideo,
    setHasVideo,
    deviceConfig,
    camUnaviable,
  } = useSip();
  const { user, updateDevice } = useAuth();
  const { t } = useTranslation();
  const handRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerLayoutRef = useRef<HTMLDivElement>(null);
  const [show] = useState(true);
  const [someoneAskedToSpeak, setSomeoneAskedToSpeak] =
    useState<boolean>(false);
  const [someoneAskedToSpeakRed, setSomeoneAskedToSpeakRed] =
    useState<boolean>(false);
  const [showLayouts, setShowLayouts] = useState(false);
  const [showHandsUpParticipants, setShowHandsUpParticipants] =
    useState<boolean>(false);
  const [hasVideoConfig, setHasVideoConfig] = useState(false);
  const [hasMicConfig, setHasMicConfig] = useState(false);

  const [oldUsersWhoseWantToSpeakLenght, setOldUsersWhoseWantToSpeakLenght] =
    useState<RequestSpeakEvent[]>([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [showVideoConfig, setShowVideoConfig] = useState(false);
  const [showMicConfig, setShowMicConfig] = useState(false);


  useEffect(() => {
    if (usersWhoseWantToSpeak.length === 0)
      setOldUsersWhoseWantToSpeakLenght([]);
  }, [usersWhoseWantToSpeak.length]);

  useEffect(() => {
    const isMe = usersWhoseWantToSpeak.find(
      spk =>
        spk.userName.split('<squad>')[1] ===
        (user?.name.split('<squad>')[1] as string),
    );

    const oldMe = oldUsersWhoseWantToSpeakLenght.find(
      spk =>
        spk.userName.split('<squad>')[1] ===
        (user?.name.split('<squad>')[1] as string),
    );

    if (usersWhoseWantToSpeak.length > 0) {
      if (
        oldUsersWhoseWantToSpeakLenght.length !== usersWhoseWantToSpeak.length
      ) {
        if (!showHandsUpParticipants && !isMe) {
          setSomeoneAskedToSpeakRed(true);
        }
        setOldUsersWhoseWantToSpeakLenght(usersWhoseWantToSpeak);
      }

      if (
        oldUsersWhoseWantToSpeakLenght.length < usersWhoseWantToSpeak.length
      ) {
        const oldMe = oldUsersWhoseWantToSpeakLenght.find(
          spk =>
            spk.userName.split('<squad>')[1] ===
            (user?.name.split('<squad>')[1] as string),
        );
        if (oldMe && isMe) {
          setSomeoneAskedToSpeakRed(true);
        }
      }
    } else {
      setSomeoneAskedToSpeakRed(false);
    }
  }, [
    oldUsersWhoseWantToSpeakLenght,
    showHandsUpParticipants,
    user?.name,
    usersWhoseWantToSpeak,
    usersWhoseWantToSpeak.length,
  ]);

  const [layouts] = useState<ILayoutsValue[]>([
    {
      value: 'group:grid',
      label: 'Grid distribute',
    },
    {
      value: 'group:grid-zoom',
      label: 'Highlight users',
    },
    {
      value: '1up_top_left+9',
      label: 'Highlight who talks to others around',
    },
    {
      value: 'overlaps',
      label: 'Highlight who talks to others below',
    },
    {
      value: 'presenter-overlap-small-bot-right',
      label: 'Highlight presentation bottom',
    },
    {
      value: 'presenter-overlap-small-top-right',
      label: 'Highlight presentation top',
    },
  ]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setUsersWhoseWantToSpeak([]);
  }, [setUsersWhoseWantToSpeak]);

  const toggleHandsUpParticipants = () => {
    setShowHandsUpParticipants(!showHandsUpParticipants);
    setSomeoneAskedToSpeakRed(false);
  };
  const readMessages = () => {
    setNewMessages(false);
  };

  useEffect(
    () => setSomeoneAskedToSpeak(!!usersWhoseWantToSpeak[0]),
    [usersWhoseWantToSpeak, setSomeoneAskedToSpeak, user],
  );

  const handleMobileScreen = (widthScreen: number) => {
    // console.log('widthScreen', widthScreen);
  };

  useEffect(() => {
    handleMobileScreen(width);
  }, [width]);

  const handleEndCallWithSharedScreen = () => {
    const userId = user?.name.split('<squad>')[1];
    if (inScreenShare) {
      const handle = participants.find(aux => aux.name.includes('(Screen)'));
      if (userId && handle?.name.includes(userId)) {
        screenShare();
      }
    }
  };

  const handleShowLayouts = useCallback(() => {
    setShowLayouts((prev: boolean) => {
      return !prev;
    });
  }, []);

  const changeVideo = useCallback(() => {
    if (videoSocket) {
      muteCam();
    } else {
      unMuteCam();
    }
    setDeviceConfig(state => {
      return {
        ...state,
        useVideo: !video,
      };
    });
    setVideo(!video);
    setHasVideo(state => {
      return !state;
    });
  }, [
    setDeviceConfig,
    setVideo,
    video,
    setHasVideo,
    videoSocket,
    muteCam,
    unMuteCam,
  ]);

  // const openVideoConfig = useCallback(() => {
  //   setShowVideoConfig(!showVideoConfig);
  // }, [showVideoConfig]);

  const changeVideoDevice = useCallback(
    (videoDevice: string) => {
      setDeviceConfig({
        ...deviceConfig,
        videoId: videoDevice,
      });
      camResCheck(videoDevice);
      handleChangeAnyDevice(videoDevice);
    },
    [deviceConfig, handleChangeAnyDevice, setDeviceConfig],
  );

  const changeAudioDevice = useCallback(
    (audioDevice: string) => {
      const data = {
        ...deviceConfig,
        audioId: audioDevice,
      };

      setDeviceConfig(state => {
        return {
          ...state,
          audioId: audioDevice,
        };
      });
      updateDevice(data);
      setCurrentAudioDevice(audioDevice);
      handleChangeMicDevice(audioDevice);
    },

    [deviceConfig, handleChangeMicDevice, setCurrentAudioDevice, setDeviceConfig, updateDevice],
  );

  const changeSpeakerDevice = useCallback(
    (speakerDevice: string) => {
      const data = {
        ...deviceConfig,
        speakerId: speakerDevice,
      };

      setDeviceConfig(state => {
        return {
          ...state,
          speakerId: speakerDevice,
        };
      });
      updateDevice(data);
      setCurrentSpeakerDevice(speakerDevice);
      handleChangeSpeackerDevice(speakerDevice);
      console.log(
        'deviceSpeakerId changeSpeakerDevice',
        speakerDevice,
      );
    },
    [deviceConfig, handleChangeSpeackerDevice, setCurrentSpeakerDevice, setDeviceConfig, updateDevice],
  );

  const changeMic = useCallback(() => {
    if (micSocket) {
      muteMic();
    } else {
      unMuteMic();
    }
    setDeviceConfig(state => {
      return {
        ...state,
        useAudio: !micSocket,
      };
    });
    setMic(!mic);
    setHasAudio(state => {
      return !state;
    });
  }, [micSocket, setDeviceConfig, setMic, mic, setHasAudio, muteMic, unMuteMic]);

  
  const openMicConfig = useCallback(() => {
    setShowMicConfig(!showMicConfig);
  }, [showMicConfig]);

  const micButtons = () => {
    return (
      <>
        <MeetConfigMicButton
          changeMic={changeMic}
          mic={mic}
          micInputDevices={deviceMic}
          micOutputDevices={deviceSpeaker}
          setShowMicConfig={openMicConfig}
          showMicConfig={showMicConfig}
          changeAudioDevice={changeAudioDevice}
          changeSpeakerDevice={changeSpeakerDevice}
          currentAudio={currentAudioDevice}
          currentSpeaker={currentSpeakerDevice}
          closeConfig={() => setShowMicConfig(false)}
          videoActions
        />
      </>
    );
  };

  useEffect(() => {
    if (deviceConfig.audioId === '') {
      setHasMicConfig(false);
    } else {
      setHasMicConfig(true);
    }

    if (deviceConfig.videoId === '') {
      setHasVideoConfig(false);
    } else {
      setHasVideoConfig(true);
    }
  }, [deviceConfig]);

  const openVideoConfig = useCallback(() => {
    setShowVideoConfig(!showVideoConfig);
  }, [showVideoConfig]);

  return (
    <>
      <div
        ref={containerRef}
        className={`video-actions-container-bottom ${show && 'show'}`}
      >
        {width <= 442 ? (
          <>
            <div className="secondContainer">
              {/* Fechar mic */}
              <button
                type="button"
                disabled={isBlocked}
                className={`button-call-action ${!micSocket && 'active'}`}
                onClick={debounce(
                  () => {
                    if (micSocket) {
                      muteMic();
                    } else {
                      unMuteMic();
                    }
                  },
                  500,
                  {
                    leading: true,
                    trailing: false,
                  },
                )}
              >
                {micSocket ? <RiMicLine /> : <RiMicOffLine />}
              </button>
              {/* fechar video */}
              <button
                type="button"
                disabled={isBlocked}
                className={`button-call-action ${
                  !video && 'active'
                } cameraIcon`}
                onClick={debounce(
                  () => {
                    if (video) {
                      muteCam();
                    } else {
                      unMuteCam();
                    }
                  },
                  500,
                  {
                    leading: true,
                    trailing: false,
                  },
                )}
              >
                {video ? <RiCameraLine /> : <RiCameraOffLine />}
              </button>
              <button
                id="upHand"
                type="button"
                style={{
                  background: `${
                    usersWhoseWantToSpeak.find(
                      spk =>
                        spk.userName.split('<squad>')[1] ===
                        (user?.name.split('<squad>')[1] as string),
                    )
                      ? '#A3D241'
                      : '#3b3f42'
                  }`,
                }}
                onClick={() => {
                  toggleHandsUpParticipants();
                }}
                className={`button-call-hands-up-green-toggle ${
                  !someoneAskedToSpeak ? 'd-none' : ''
                } ${!showHandsUpParticipants ? 'show-list' : ''}`}
              >
                <IoHandRightOutline />
              </button>
              {/* Levantar a mao */}
              <button
                type="button"
                disabled={isBlocked}
                className={`button-call-action ${
                  someoneAskedToSpeak && 'd-none'
                } ${showHandsUpParticipants ? 'show-list' : ''}`}
                onClick={() => {
                  toggleHandsUpParticipants();
                }}
              >
                <IoHandRightOutline />
              </button>
              {showHandsUpParticipants && (
                <div className="dropdown-layouts uphand-container-body">
                  <ul style={{ textAlign: 'start' }}>
                    <li
                      className="layout-item hands-up"
                      onClick={() => requestSpeak()}
                    >
                      {usersWhoseWantToSpeak.find(
                        spk => spk.userName === (user?.name as string),
                      )
                        ? t('Lower your hand')
                        : t('Raise your hands')}
                    </li>
                    <hr />
                    {usersWhoseWantToSpeak.map(participant => (
                      <li
                        key={participant.userId}
                        className="layout-item users"
                      >
                        <div
                          className={`d-flex flex-row align-items-center justify-content-center ${'green'}`}
                        >
                          <IoHandRightOutline
                            size={20}
                            style={{ marginRight: '5px', marginTop: '2px' }}
                          />
                          <div className="text-name">
                            {parseName(participant.userName)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {/* mais opcoes */}
              {params?.conferenceType !== 'group' ? (
                <button
                  type="button"
                  className="button-call-action dropdown-button"
                  disabled={isBlocked}
                  onClick={handleShowLayouts}
                >
                  <BiDotsHorizontalRounded />
                  {showLayouts && (
                    <div className="dropdown-layouts layouts">
                      <ul>
                        <li
                          key="AbrirOuFecharChat"
                          className="layout-item"
                          onClick={() => {
                            openChat();
                            readMessages();
                          }}
                        >
                          Chat
                        </li>
                        <li
                          key="AbrirOuFecharChat"
                          className="layout-item"
                          onClick={() => {
                            openParticipants();
                          }}
                        >
                          Participantes
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              ) : (
                <></>
              )}
              {/* finalizar chamada */}
              <button
                type="button"
                disabled={isBlocked}
                className="button-call-action end-call"
                onClick={() => {
                  handleEndCallWithSharedScreen();
                  // endCall();
                }}
              >
                <RiPhoneLine />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="containerDropdown">
              <DropdownLanguage />
            </div>
            <div className="firstContainer">
              <button
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                type="button"
                disabled={isBlocked}
                style={{
                  backgroundColor: 'transparent',
                  color: 'transparent',
                }}
                className="button-call-action settings"
              >
                .
              </button>
            </div>
            <div className="secondContainer d-flex align-items-center">
              <MeetConfigVideoButton
                video={video}
                devices={deviceVideo as MediaDeviceInfo[]}
                changeVideo={changeVideo}
                setShowVideoConfig={openVideoConfig}
                showVideoConfig={showVideoConfig}
                changeVideoDevice={changeVideoDevice}
                deviceConfig={deviceConfig}
              />

              {/* mut mic */}
              {micButtons()}

              {/* Abrir meu video RiEyeOffLine RiEyeLine  */}
              {camUnaviable ? (
                <>
                  <button
                    type="button"
                    className="button-call-action"
                    style={{ backgroundColor: '#c43b5b', cursor: 'default' }}
                    disabled={isBlocked}
                    onClick={() => {
                      console.log('cam is not found');
                    }}
                  >
                    <RiEyeOffLine />
                  </button>
                </>
              ) : (
                <>
                <button
                  type="button"
                  className="button-call-action"
                  disabled={isBlocked}
                  onClick={openMyVideo}
                >
                  <RiEyeLine />
                </button>
                </>
              )}

              {/* Compartilhar tela */}
              <button
                type="button"
                className={`button-call-action ${inScreenShare && 'active'}`}
                disabled={isBlocked}
                onClick={screenShare}
              >
                {inScreenShare ? <MdStopScreenShare /> : <MdScreenShare />}
              </button>

              {/* mudar layout */}
              <button
                type="button"
                className="button-call-action dropdown-button"
                disabled={isBlocked}
                onClick={handleShowLayouts}
              >
                <RiLayoutMasonryLine />
                {showLayouts && (
                  <div
                    className="dropdown-layouts layouts"
                    ref={containerLayoutRef}
                  >
                    <ul style={{ padding: '0px' }}>
                      {layouts.map(item => (
                        <li
                          key={item.value}
                          className="layout-item"
                          onClick={() => changeLayout(item.value)}
                        >
                          {t(item.label)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </button>

              <button
                id="upHand"
                type="button"
                style={{
                  background: `${
                    usersWhoseWantToSpeak.find(
                      spk =>
                        spk.userName.split('<squad>')[1] ===
                        (user?.name.split('<squad>')[1] as string),
                    )
                      ? '#A3D241'
                      : '#3b3f42'
                  }`,
                }}
                onClick={() => {
                  toggleHandsUpParticipants();
                }}
                className={`button-call-action dropdown-button button-call-hands-up-green-toggle ${
                  !someoneAskedToSpeak ? 'd-none' : ''
                } ${!showHandsUpParticipants ? 'show-list' : ''}`}
              >
                <IoHandRightOutline />
                {someoneAskedToSpeakRed && (
                  <div className={` 'd-none' newMessagePing`} />
                )}
                {!showHandsUpParticipants && (
                  <div
                    id="list-handup"
                    className={`dropdown-layouts layouts uphand-container-body `}
                  >
                    <ul style={{ textAlign: 'start', padding: '0px' }}>
                      <li
                        className="layout-item hands-up"
                        onClick={() => requestSpeak()}
                      >
                        {usersWhoseWantToSpeak.find(
                          spk => spk.userName === (user?.name as string),
                        )
                          ? t('Lower your hand')
                          : t('Raise your hands')}
                      </li>
                      <hr />
                      {usersWhoseWantToSpeak.map(participant => (
                        <li
                          key={participant.userId}
                          className="layout-item users"
                        >
                          <div
                            className={`d-flex flex-row align-items-center justify-content-center ${'green'}`}
                          >
                            <IoHandRightOutline
                              size={20}
                              style={{ marginRight: '8px', marginTop: '2px' }}
                            />
                            <div className="textName">
                              {parseName(participant.userName)}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </button>

              <button
                type="button"
                id="upHand"
                ref={handRef}
                disabled={isBlocked}
                className={`button-call-action dropdown-button ${
                  someoneAskedToSpeak && 'd-none'
                } ${showHandsUpParticipants ? 'show-list' : ''}`}
                onClick={() => {
                  toggleHandsUpParticipants();
                }}
              >
                <IoHandRightOutline />
                {someoneAskedToSpeakRed && (
                  <div className={` 'd-none' newMessagePing`} />
                )}

                {showHandsUpParticipants && (
                  <div
                    id="list-handup"
                    className="dropdown-layouts layouts uphand-container-body before-ocult"
                  >
                    <ul style={{ textAlign: 'start', padding: '0px' }}>
                      <li
                        className="layout-item hands-up"
                        onClick={() => requestSpeak()}
                      >
                        {usersWhoseWantToSpeak.find(
                          spk => spk.userName === (user?.name as string),
                        )
                          ? t('Lower your hand')
                          : t('Raise your hands')}
                      </li>
                      <hr />
                      {usersWhoseWantToSpeak.map(participant => (
                        <li
                          key={participant.userId}
                          className="layout-item users"
                        >
                          <div
                            className={`d-flex flex-row align-items-center justify-content-center ${'green'}`}
                          >
                            <IoHandRightOutline
                              size={20}
                              style={{ marginRight: '8px', marginTop: '2px' }}
                            />
                            <div className="textName">
                              {parseName(participant.userName)}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </button>

              <>
                {params?.conferenceType !== 'group' ? (
                  <button
                    type="button"
                    className="button-call-action msg-button"
                    disabled={isBlocked}
                    onClick={() => {
                      openChat();
                      readMessages();
                    }}
                  >
                    <RiMessage3Line />
                    <div
                      className={`${!newMessages && 'd-none'} newMessagePing`}
                    />
                  </button>
                ) : (
                  <></>
                )}
                <button
                  type="button"
                  className="button-call-action"
                  disabled={isBlocked}
                  onClick={openParticipants}
                >
                  <RiGroupLine />
                </button>
              </>

              <button
                type="button"
                className="button-call-action end-call"
                onClick={endCall}
              >
                <RiPhoneLine />
              </button>
            </div>
            <div className="thirdContainer">
              <button
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                type="button"
                style={{
                  backgroundColor: 'transparent',
                  color: 'transparent',
                }}
                className="button-call-action settings"
              >
                .
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VideoCallActions;
