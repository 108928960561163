/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelTokenSource } from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { camResCheck } from '../services/utils/camResCheck';
import { useSip } from './sip';
import apiDps from '../services/dps';
import IConferenceApiResponse, {
  IConferenceChatResponse,
  IConferenceVoiceResponse,
} from '../interfaces/conference';

interface DevicesContextState {
  deviceMic: MediaDeviceInfo[] | undefined;
  deviceVideo: MediaDeviceInfo[] | undefined;
  deviceSpeaker: MediaDeviceInfo[] | undefined;
  setDeviceMic(config: MediaDeviceInfo[]): void;
  setDeviceVideo(config: MediaDeviceInfo[]): void;
  setDeviceSpeaker(config: MediaDeviceInfo[]): void;
  currentAudioDevice: string;
  setCurrentAudioDevice(currentAudioDevice: string): void;
  currentSpeakerDevice: string;
  setCurrentSpeakerDevice(currentAudioDevice: string): void;
  conferenceTitle: string;
  setConferenceTitle(currentAudioDevice: string): void;
  eventsSocketUrl: string;
  setEventsSocketUrl(currentAudioDevice: string): void;
  mic: boolean;
  setMic(currentAudioDevice: boolean): void;
  video: boolean;
  setVideo(currentAudioDevice: boolean): void;
  loadingButton: boolean;
  setLoadingButton(currentAudioDevice: boolean): void;
  chatConference: IConferenceChatResponse | undefined;
  setChatConference(
    currentAudioDevice: IConferenceChatResponse | undefined,
  ): void;
  voiceConference: IConferenceVoiceResponse | undefined;
  setVoiceConference(
    currentAudioDevice: IConferenceVoiceResponse | undefined,
  ): void;
}

const DevicesContext = createContext<DevicesContextState>(
  {} as DevicesContextState,
);

export const DevicesProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const {
    conferenceId,
    pin,
    params,
    hasIremoved,
    setLoading,
    devices,
    setDevices,
    deviceConfig,
    setDeviceConfig,
    setDeviceSizeConfig,
    setInitialState,
    hasAudio,
    setHasAudio,
    hasVideo,
    setHasVideo,
  } = useSip();
  const [deviceMic, setDeviceMic] = useState<MediaDeviceInfo[] | undefined>(
    undefined,
  );
  const [deviceVideo, setDeviceVideo] = useState<MediaDeviceInfo[] | undefined>(
    undefined,
  );
  const [deviceSpeaker, setDeviceSpeaker] = useState<
    MediaDeviceInfo[] | undefined
  >(undefined);

  const [currentAudioDevice, setCurrentAudioDevice] = useState('');
  const [currentSpeakerDevice, setCurrentSpeakerDevice] = useState('');
  const [mic, setMic] = useState(true);
  const [video, setVideo] = useState(true);
  const [chatConference, setChatConference] = useState<
    IConferenceChatResponse | undefined
  >(undefined);
  const [voiceConference, setVoiceConference] = useState<
    IConferenceVoiceResponse | undefined
  >(undefined);
  const [conferenceTitle, setConferenceTitle] = useState('Join the conference');
  const [eventsSocketUrl, setEventsSocketUrl] = useState<string>('');
  const [loadingButton, setLoadingButton] = useState(true);

  const getMediaDevices = useCallback(async () => {
    try {
      const premisson = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
        // @ts-ignore
        displaySurface: 'monitor',
      });

      const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
      const deviceVideoList = enumerateDevices.filter(
        item => item.kind === 'videoinput',
      );

      deviceVideoList.forEach(v => {
        camResCheck(
          v.deviceId,
          (newDevice: MediaTrackConstraints | undefined) => {
            if (newDevice) {
              setDeviceSizeConfig(state => {
                const hasCamConfig = state?.find(
                  device => device.deviceId === newDevice.deviceId,
                );

                if (hasCamConfig) {
                  return [
                    ...(state?.filter(
                      device => device.deviceId !== newDevice.deviceId,
                    ) as MediaTrackConstraints[]),
                    hasCamConfig,
                  ];
                }
                if (hasCamConfig === undefined) {
                  if (state) {
                    return [...(state as MediaTrackConstraints[]), newDevice];
                  }
                }
                return [newDevice];
              });
            }
          },
        );
      });

      const deviceAudioList = enumerateDevices.filter(
        item => item.kind === 'audioinput' && item.deviceId !== 'default',
      );
      const deviceSpeakerList = enumerateDevices.filter(
        item => item.kind === 'audiooutput' && item.deviceId !== 'default',
      );

      let localHasAudio = false;

      setHasAudio(state => {
        localHasAudio = state;
        return state;
      });

      let localHasVideo = false;

      setHasVideo(state => {
        localHasVideo = state;
        return state;
      });

      setDeviceConfig(state => {
        if (state.useAudio) {
          setCurrentAudioDevice(state.audioId);
          setCurrentSpeakerDevice(state.speakerId);
          setVideo(state.useVideo);
          setMic(state.useAudio);
          return state;
        }
        setCurrentAudioDevice(
          deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
        );

        setCurrentSpeakerDevice(
          deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
        );
        return {
          useVideo: localHasVideo,
          useAudio: localHasAudio,
          audioId:
            deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
          speakerId:
            deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
          videoId:
            deviceVideoList.length > 0 ? deviceVideoList[0].deviceId : '',
        };
      });
      setDevices(deviceVideoList);
      setDeviceMic(deviceAudioList);
      setDeviceSpeaker(deviceSpeakerList);
    } catch (error) {
      const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
      const deviceVideoList = enumerateDevices.filter(
        item => item.kind === 'videoinput',
      );
      deviceVideoList.forEach(v => {
        camResCheck(
          v.deviceId,
          (newDevice: MediaTrackConstraints | undefined) => {
            if (newDevice) {
              setDeviceSizeConfig(state => {
                const hasCamConfig = state?.find(
                  device => device.deviceId === newDevice.deviceId,
                );

                if (hasCamConfig) {
                  return [
                    ...(state?.filter(
                      device => device.deviceId !== newDevice.deviceId,
                    ) as MediaTrackConstraints[]),
                    hasCamConfig,
                  ];
                }
                if (hasCamConfig === undefined) {
                  if (state) {
                    return [...(state as MediaTrackConstraints[]), newDevice];
                  }
                }
                return [newDevice];
              });
            }
          },
        );
      });
      const deviceAudioList = enumerateDevices.filter(
        item => item.kind === 'audioinput' && item.deviceId !== 'default',
      );
      const deviceSpeakerList = enumerateDevices.filter(
        item => item.kind === 'audiooutput' && item.deviceId !== 'default',
      );
      let localHasAudio = false;

      setHasAudio(state => {
        localHasAudio = state;
        return state;
      });

      let localHasVideo = false;

      setHasVideo(state => {
        localHasVideo = state;
        return state;
      });

      setDeviceConfig(state => {
        if (state.useAudio) {
          setCurrentAudioDevice(state.audioId);
          setCurrentSpeakerDevice(state.speakerId);
          setVideo(state.useVideo);
          setMic(state.useAudio);
          return state;
        }
        setCurrentAudioDevice(
          deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
        );

        setCurrentSpeakerDevice(
          deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
        );
        return {
          useVideo: localHasVideo,
          useAudio: localHasAudio,
          audioId:
            deviceAudioList.length > 0 ? deviceAudioList[0].deviceId : '',
          speakerId:
            deviceSpeakerList.length > 0 ? deviceSpeakerList[0].deviceId : '',
          videoId:
            deviceVideoList.length > 0 ? deviceVideoList[0].deviceId : '',
        };
      });

      setDevices(deviceVideoList);
      setDeviceMic(deviceAudioList);
      setDeviceSpeaker(deviceSpeakerList);
    }
  }, [setDeviceConfig, setDevices, setDeviceSizeConfig]);

  const getConferenceInfo = useCallback(
    async (params: any, source: CancelTokenSource) => {
      let domainComming = '';
      if (window.location.hash.includes('domain=')) {
        // eslint-disable-next-line prefer-destructuring
        domainComming = window.location.hash.split('domain=')[1];
      }
      if (conferenceId.length) {
        if (pin) {
          const response = await apiDps.get('', {
            params: {
              domain: params.domain !== '' ? params.domain : 'abelha',
            },
          });
          const responseApi = await axios.get<IConferenceApiResponse>(
            `${response.data.api_url}conferences/anonymous/${conferenceId}`,
            { params: { pin }, cancelToken: source.token },
          );
          const { voice, chat, eventSocketURL } = responseApi.data;
          setConferenceTitle(voice.conferenceName);
          setChatConference(chat);
          setVoiceConference(voice);
          setEventsSocketUrl(eventSocketURL);
        }
        if (
          params?.isSquad === 'true' &&
          params.conferenceType === 'group' &&
          params.conferenceTitle
        ) {
          setConferenceTitle(decodeURI(params.conferenceTitle));
          setVoiceConference({
            user: params.sipUser,
            password: params.sipPw,
            hostname: params.sipDomain,
            websocketUrl2: params.websocketUrl2,
          } as IConferenceVoiceResponse);
          setEventsSocketUrl(params.socketUrl);
        }
        setLoadingButton(false);
      }
    },
    [conferenceId, pin, setEventsSocketUrl],
  );

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    if (params) {
      getMediaDevices();
      getConferenceInfo(params, source);
    }
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [getConferenceInfo, getMediaDevices, params]);

  return (
    <DevicesContext.Provider
      value={{
        deviceMic,
        deviceVideo,
        setDeviceMic,
        setDeviceVideo,
        deviceSpeaker,
        setDeviceSpeaker,
        currentAudioDevice,
        setCurrentAudioDevice,
        currentSpeakerDevice,
        setCurrentSpeakerDevice,
        conferenceTitle,
        setConferenceTitle,
        eventsSocketUrl,
        setEventsSocketUrl,
        mic,
        setMic,
        video,
        setVideo,
        loadingButton,
        setLoadingButton,
        chatConference,
        setChatConference,
        voiceConference,
        setVoiceConference,
      }}
    >
      {children}
    </DevicesContext.Provider>
  );
};

export const useDevices = (): DevicesContextState => {
  const context = useContext(DevicesContext);

  if (!context) {
    throw new Error('useAuth must be used within an DevicesProvider');
  }

  return context;
};
