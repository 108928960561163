import React from 'react';

import './styles.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  participants: string;
}

const CallParticipants: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { participants } = props;
  return (
    <div className="conference-users-joined">
      {participants === '' ? (
        <span>{t(participants)}</span>
      ) : (
        <span
          style={{
            maxWidth: '350px',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            textOverflow: 'ellipsis',
          }}
        >
          {t(participants)}
        </span>
      )}
    </div>
  );
};

export default CallParticipants;
