/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import DropdownLanguage from '../../DropdownLanguage';
import { useAuth } from '../../hooks/auth';

interface IProps {
  onReturn(): void;
  hasIremoved: boolean;
}

const CallEnded: React.FC<IProps> = props => {
  const { onReturn, hasIremoved } = props;
  const { user } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    posthog.capture('singOut-meet', { userId: user?.id });
  }, []);

  return (
    <>
      <div className="ended-conference">
        <div className="card-ended-conference">
          <span className="title">
            {hasIremoved ? t('RemovedFromCall') : t('You left the meeting!')}
          </span>
          <div className="ended-conference-container">
            <button
              onClick={onReturn}
              type="button"
              style={{ borderWidth: 0.5 }}
            >
              {t('Return to home page')}
            </button>
          </div>
          <img src="./logo-squad-transparent.png" alt="squad" />
        </div>
      </div>
      <div className="dropdown-language">
        <DropdownLanguage />
      </div>
    </>
  );
};

export default CallEnded;
